.base-dialog {
  &__close-icon {
    position: absolute;
    top: 8px;
    right: 8px;
  }

  .MuiDialog-paper {
    border: 4px solid var(--card-item-background);
    border-radius: 4px;

    width: 780px;
    max-width: 780px;
    padding: 64px 56px;
    background: var(--card-background) !important;
  }

  .MuiDialogTitle-root,
  .MuiDialogContent-root {
    padding: 0;
    background: var(--card-background) !important;
  }
}
