.base-view {
  height: 100%;
  overflow: hidden;
  padding-bottom: 16px;
  &__head {
    position: relative;
    padding: 10px 20px 20px;
    color: var(--text-color);
    box-shadow: 0px 8px 10px -1px rgba(0, 0, 0, 0.13);
  }
  &__main {
    height: 100%;
    padding-bottom: 16px;
    background-color: var(--scrollbar-thumb);

    .MuiTable-root th, .MuiTable-root td {
      padding: 10px 20px;
    }
  }
  .head {
    &__top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 18px;
    }

    &__caption {
      font-size: 24px;
      color: var(--text-color);
    }

    &__close {
      width: 24px;
      height: 24px;
      cursor: pointer;

      &:hover {
        svg {
          fill: var(--text-color);
        }
      }

      svg {
        fill: var(--text-color-light);
        transition: all .3s;
      }
    }
  }
  &__footer {
    font-size: 12px;
    color: var(--text-color);
    display: flex;
    justify-content: flex-end;
    width: 100%;
    position: absolute;
    bottom: 0;
    background: var(--background-color);
    box-shadow: 0px -3px 8px 0px rgba(0, 0, 0, 0.25);
    padding: 12px 20px;
    z-index: 2;
  }
}