@use '~styles/mixins.scss' as mixins;

.container {
  display: flex;
  flex-basis: 100%;
  position: relative;
  max-width: 383px;
  min-width: 184px;
  height: 60px;
  margin: 0 10px 16px 5px;
  background-color: var(--card-item-background-light);
  border-radius: 4px;
  padding: 8px;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;

  &.fixSize {
    max-width: 184px;
  }

  &:hover {
    outline: 1px solid var(--text-color);

    .duration {
      display: none;
    }

    .add {
      display: block;
    }

    .genre {
      color: var(--activity-color);
    }
  }


  &.playing {
    outline: 2px solid var(--activity-color);
  }


  .track {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 8px;

    &.buffering {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  .btn {
    height: 24px;
    width: 24px;
    min-width: 24px;
    padding: 0px;
    margin-bottom: 8px;
    margin-left: 2px;
  }

  .playIcon {
    &:first-child {
      padding-left: 3px;
    }
  }

  .play {
    border-radius: 50%;
    background-color: #696969;

    &:hover {
      background-color: #9B9A9A;
    }
  }

  .fill {
    fill: white;

    path {
      fill: white;
    }
  }

  .meta {
    margin-left: 4px;
    width: calc(100% - 95px);

    div {
      min-width: 72px;
      max-width: 270px;
      @include mixins.ellipsisTextOverflow;

      &:first-child{
        margin-bottom: 8px;
      }
    }
  }

  .title {
    font-weight: 700;
    color: var(--text-color);
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .genre {
    width: 190px;
    color: var(--text-color-light);
  }

  .add {
    display: none;
    background-color: var(--activity-color);
    border-radius: 4px;
    margin-left: auto;
    fill: white;

    &:hover {
      background-color: var(--activity-color-hovered)
    }
  }

  .duration {
    position: absolute;
    top: 12px;
    right: 8px;
    color: var(--text-color-light);
  }


  .progress {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    background-repeat: repeat-x;
    background-size: 6px 4px;
    background-image: linear-gradient(-70deg, var(--activity-color) 4px, var(--text-color) 4px, var(--text-color) 6px, var(--activity-color) 6px);
    animation: progress .3s linear infinite
  }
}

@keyframes progress {
  to {
    background-position: 6px 0;
  }
}