.combobox {
  min-width: 120px;
  
  & > *:first-child {
    margin-right: 6px;
  }
}

.menu {
  padding-top: 8px;
}