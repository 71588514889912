.message-box {
  .MuiDialogTitle-root {
    padding-top: 16px;
    padding-bottom: 4px;
    text-align: center;
  }

  .MuiDialogContent-root {
    padding: 0px 24px;
    text-align: center;
    font-size: 18px;
    line-height: 21px;
  }

  .MuiDialogActions-root {
    padding: 8px;
    justify-content: center;
  }

  .MuiButton-root.btn {
    width: 93px;
    height: 40px;
    border-radius: 8px;
  }
}
