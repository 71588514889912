.container {
  display: flex;
  align-items: center;
}

.checkbox {
  margin-right: 18px;
}

.labelRoot {
  width: 147px;
  margin-right: 16px;
  margin-left: 0;
}

.sliderRoot {
  width: 200px;
  margin-right: 16px;
  padding: 2px 0;
  color: var(--text-color);
}

.track {
  height: 1px;
}

.thumb:global(.Mui-disabled) {
  margin-top: -5px;
}

.mark {
  width: 5px;
  height: 5px;
  transform: translateY(-50%);
  border-radius: 50%;
  color: var(--inactive-color);
  margin-top: 1px;
}

.markActive {
  opacity: 0;
}

.input {
  width: 63px;
}