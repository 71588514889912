.scroll-container {
  &__content {
    /* nothing so far */
  }
}

.scrollbar {
  background-color: var(--scrollbar-background);
  z-index: 2;

  &--vertical {
    height: 100%;
    right: 0;
  }

  &--horizontal {
    width: 100%;
    bottom: 0;
  }
}

.scroll-thumb {
  background-color: var(--scrollbar-thumb);
  cursor: pointer;
  border-radius: 2px;
}
