.nablet-hld-settings {
  padding: 20px;
  height: 100%;
  overflow: hidden;
  text-align: left;

  &__head {
    padding-bottom: 23px;
  }

  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 18px;
  }

  &__caption {
    font-size: 24px;
    color: var(--text-color);
  }

  &__close {
    width: 24px;
    height: 24px;
    cursor: pointer;

    &:hover {
      svg {
        fill: var(--text-color);
      }
    }

    svg {
      fill: #9d9d9d;
      transition: all .3s;
    }
  }

  &__controls {
    display: flex;
    justify-content: space-between;
    gap: 20px;
  }

  &__params {
    display: flex;
    width:100%;
    flex-direction: column; /* Align children vertically */
    gap: 16px;
  }

  &__onnx{
    min-width:200px;

    .MuiSelect-root {
      min-width: 200px;
    }
  }

  &__duration{
    min-width:200px;
    .MuiInput-root {
      max-width: 200px;
    }
    .MuiInputBase-input {
      text-align: right;
    }
    input {
      background-color: var(--card-background);
      border: 1px solid var(--card-background);
      border-radius: 4px;
      display: block;
      width: 100%;
      height: 33px;
      color: var(--text-color);
      padding: 0 8px;
      font-size: 13px;
      transition: all .3s;

      &:hover,
      &:focus {
        border-color: var(--text-color);
      }
    }
  }

  &__label {
    font-size: 14px;
    color: var(--text-color);
    margin-bottom: 10px;
    display: block;
  }

  &__btn-start {
    min-width: 105px;
  }

  &__btn-create-project {
    min-width: 120px;
    margin-bottom: 10px;
  }

  &__body {
    padding: 24px 0;
  }

  &__info {
    font-size: 14px;
    margin-bottom: 10px;
    display: block;

    &-item {
      color: #8e8e8e;
      margin-bottom: 8px;

      span {
        color: var(--text-color);
        margin-left: 5px;
      }
    }
  }

  &__status {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
  }

  &__btn-cancel {
    width: 105px;
  }

  &__text {
    margin-bottom: 16px;
    margin-top: 30px;
    width: 100%;

    p {
      font-size: 14px;
      color: var(--text-color);
    }
  }

  &__loader {
    width: 100%;
    height: 28px;
    border: 1px solid var(--text-color);
    background-color: var(--card-background);
    border-radius: 18px;
    padding: 3px;

    &-inner {
      background-color: var(--activity-color);
      border-radius: 18px;
      height: 100%;
    }
  }
}

  //TODO: remove after demo
  .MuiDialogTitle-root,
  .MuiDialogContent-root,
  .MuiDialogActions-root {
    background: var(--card-item-background-light);
    color: var(--text-color);
  }

  .MuiTypography-colorTextSecondary {
    color: var(--text-color) !important;
  }
