$popover-width: 418px;
$popover-height: 60px;

.offline-mode-plug {
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 999;

  user-select: none;

  &--hidden {
    visibility: hidden;
  }

  &::after {
    content: "";
    opacity: 0.7;
    background-color: var(--border-color);
    width: inherit;
    height: inherit;
    position: absolute;
  }

  &__popover {
    background: var(--activity-color);
    box-shadow: 2px 4px 8px -2px rgba(0, 19, 118, 0.4);
    border-radius: 5px;

    width: $popover-width;
    height: $popover-height;
    left: calc(50% - #{$popover-width} / 2);
    top: calc(90% - #{$popover-height} / 2);
    position: absolute;
    z-index: 1;

    padding: 12px 24px;
    display: flex;
    align-items: center;

    font-size: 14px;
    line-height: 16px;
    color: var(--text-color);

    &__icon {
      margin-right: 16px;
    }

    &__text-block {
      display: flex;
      flex-direction: column;

      &__status-text {
        color: #a4aee3;
        font-size: 12px;
        line-height: 14px;
        margin-top: 6px;
      }
    }
  }
}
