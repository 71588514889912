.dropdown-menu {
  display: flex;
  align-items: center;

  &__chevron-box {
    width: 24px;
    height: 24px;
    cursor: pointer;

    margin-left: 8px;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__chevron {
    border-bottom: 2px solid var(--text-color);
    border-left: 2px solid var(--text-color);
    transform: rotate(-45deg);

    transition: transform 0.25s;

    &--expanded {
      margin-top: 0 !important;
      transform: rotate(135deg);
    }
  }
}

.MuiList-root a {
  color: inherit !important;
  text-decoration: inherit !important;
}

.dropdown-divider {
  margin: 6px 0;
}