.settings-typography {
  &__text {
    margin-bottom: 12px;

    textarea {
      display: block;
      width: 100%;
      border: none;
      background-color: var(--card-background);
      border-radius: 4px;
      height: 80px !important;
      font-size: 12px;
      color: var(--text-color);
      padding: 10px 8px;
      resize: none;
      outline: none;
    }
  }

  &__row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -8px;
  }

  &__item {
    padding: 0 8px 12px;

    &--font {
      flex-grow: 1;
      .MuiInputBase-root {
        max-width: 100%;
        width: 100%;
        min-width: 120px;
      }
    }

    &--size .MuiInput-input {
      width: 70px;
    }

    &--radio {
      width: 176px;
    }

    &--checkbox {
      display: flex;
      align-items: center;
      margin: 0 -8px;

      .settings-typography__item {
        width: 51px;
        padding-bottom: 0;
      }
    }
  }
}
