.crumb-btn {
  cursor: pointer;
  border-radius: 4px;
  background: var(--card-item-background);
  width: 32px;
  height: 32px;
  color: var(--text-color);
  font-size: 24px;
  display: flex;
  align-items: center;

  span {
    font-family: serif;
    margin: -12px auto 0 auto;
  }
}