.img-skeleton {
  background: radial-gradient(ellipse at top, var(--border-color), transparent),
            radial-gradient(ellipse at bottom, #292929, transparent);
  animation: 1.5s linear infinite running colorChange;
}

@keyframes colorChange {
  100% {
    background-position: 184px
  }
 }

 .author-link {
  position: absolute;
  left: 0;
  bottom: 0;
  color: #fff;
  text-decoration: none;
  font-size: 12px;
  margin: 0 0 8px 8px;
  display: flex;
  align-items: center;
  visibility: hidden;

  &_icon {
    padding-right: 4px;
  }

  &_name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 140px;
  }
 }

 .source-asset:hover {
  .author-link {
    visibility: visible;
  }
 }