@import '../../styles/variables.scss';

.plug-wrapper {
    height: 100vh;
    overflow: auto;
}

.plug {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    color: var(--text-color);
    padding: 30px 10px;
    text-align: center;

    @media (min-width: 768px) {
        padding: 50px 30px;
    }

    &__logo {
        margin-bottom: 23px;
    }

    &__head {
        margin: 0 0 30px 0;
        font-size: 24px;
        font-weight: normal;

        @media (min-width: 768px) {
            font-size: 26px;
            margin-bottom: 55px;
        }
    }

    &__subhead {
        font-size: 18px;
        color: $media-svg-hover;
        max-width: 240px;
        font-weight: normal;
        margin: 0 0 25px 0;  
        
        
        @media (min-width: 768px) {
            font-size: 36px;
            margin-bottom: 45px;
            max-width: 450px;
        }
    }

    &__image {
        max-width: 501px;
        width: 100%;
        height: auto;
    }

    &__info {
        font-size: 18px;
        margin: 0 0 25px 0;
        max-width: 240px;

        @media (min-width: 768px) {
            font-size: 24px;
            margin-bottom: 35px;
            max-width: 570px;
        }
    }

    &__btn {
      background: $blue;
      border-radius: 45px;
      padding: 18px 30px;
      color: currentColor;
      font-size: 18px;
      border: none;
      text-decoration: none;

        @media (min-width: 768px) {
            padding: 26px 24px;
            font-size: 24px;
            max-width: 300px;
            width: 100%;
        }
    }


}