.media-sort {
  min-width: 94px;

  &--menu {
    width: 128px;
  }

  &.MuiPopover-paper {
    overflow: visible;

    &.MuiPaper-rounded {
      border-radius: 0;
    }

    .MuiMenuItem-root {
      overflow: visible;
      padding: 2px 5px 2px 15px;
      height: auto;
      position: relative;

      &:before {
        display: none;
      }

      &:after {
        position: absolute;
        content: '';
        border: 4px solid transparent;
        border-left: 5px solid var(--text-color);
        right: 4px;
        top: 50%;
        transform: translateY(-50%);
        transition: all .3s;
      }

      &:hover {
        .media-sort__list {
          opacity: 1;
          visibility: visible;
        }

        &:after {
          border-left-color: var(--activity-color);
        }
      }
    }

    .media-sort__item-first {
      padding: 9px 5px 9px 15px;
      &:not(:last-child) {
        border-bottom: 1px solid var(--text-color-light);
        margin-bottom: 9px;
      }
    }
  }

  .MuiList-padding {
    padding: 0 0 8px;
  }

  .MuiFormGroup-root {
    outline: none;
  }

  .MuiFormControlLabel-root {
    margin: 0;
    padding: 2px 5px 2px 25px;
    color: var(--text-color);
    transition: all .3s;
    position: relative;

    &:hover {
      color: var(--activity-color);
    }
  }

  .MuiTypography-body1 {
    font-size: 12px;
    font-family: 'Arial';
  }

  .MuiRadio-root {
    padding: 0;
  }

  &__radio-first {
    &.MuiFormControlLabel-root {
      border-bottom: 1px solid var(--text-color-light);
      padding: 9px 5px 9px 25px;
      margin-bottom: 8px;
    }
  }

  &__radio-checked {
    position: absolute;
    width: 9px;
    height: 5px;
    border-bottom: 1px solid var(--text-color);
    border-left: 1px solid var(--text-color);
    transform: rotate(-45deg);
    left: -20px;
    top: -4px;
  }

  &__btn {
    font-size: 12px;
  }

  &__list {
    position: absolute;
    left: 100%;
    padding-left: 3px;
    top: -1px;
    opacity: 0;
    visibility: hidden;
    transition: all .3s;

    &--view {
      width: 102px;
    }

    &--sort {
      width: 180px;

      .media-sort__inner {
        padding-top: 0;
      }
    }
  }

  &__inner {
    background-color: var(--panel-color);
    padding: 8px 0;
    border: 1px solid var(--border-color);
  }
}

.menu-group__title{
  .MuiFormControlLabel-label,
  .MuiFormControlLabel-label.Mui-disabled {
    color: grey;
  }
}