.media-file__controls-item {
  svg {
    path:nth-child(1) {
      fill: var(--background-color);
    }
    rect:nth-child(1) {
      fill: var(--background-color);
    }
    circle {
      fill: var(--text-color);
    }
  }
}

.media-file__controls-item--retry {
  svg path {
    fill: var(--text-color) !important;
  }
}