.save-as-dialog {
  &__header h2 {
    font-size: 24px;
    line-height: 28px;
  }

  &__content {
    margin-top: 32px;
    display: flex;
  }

  &__button {
    width: 105px !important;
  }
}
