.input-edit {
  background: var(--absolute-color);
  border: 1px solid var(--border-color-light);
  border-radius: 4px;

  flex-grow: 1;
  margin-right: 18px;
  margin-left: -12px;

  input {
    height: 24px !important;
  }

  .MuiInputBase-input {
    color: var(--text-color);
    font-size: 12px;
    caret-color: var(--border-color-light);

    padding: 6px 12px;
    height: 32px;
  }
}

.input-edit-close{
  position: absolute;
  width: fit-content;
  right: 15%;
  top: 20%;
  color: var(--text-color-light);
  font-size: 12px;
  cursor: pointer;
}