.header-solveig {
  .dropdown-menu {
    margin: auto;
  }

  &.no-title {
    justify-content: flex-end;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 20px;
  border-bottom: 2px solid var(--divider-color);

  &__logo {
    display: flex;
    align-items: center;
    &--hidden {
      display: none;
    }
    span {
      color: var(--text-color);
      font-size: 18px;
      margin-bottom: -2px;
      padding-left: 12px;
      position: relative;
      white-space: nowrap;
    }

    padding: 8px 0;
    height: 100%;

    img {
      max-width: 100% !important;
      height: 100%;
    }
  }

  &__name {
    color: var(--text-color);
    font-size: 18px;
    position: relative;
    cursor: pointer;
  }

  &__nav {
    display: flex;
    align-items: center;
    overflow: hidden;

    .dropdown-menu {
      overflow: hidden;
    }
  }

  &__status {
    font-size: 14px;
    color: var(--text-color);
    background-color: #038f33;
    border-radius: 4px;
    text-transform: uppercase;
    padding: 3px 10px;
    margin-right: 8px;
  }

  &__profile-settings-menu {
    &:before {
      position: absolute;
      content: '';
      width: 7px;
      height: 7px;
      border-bottom: 2px solid var(--text-color);
      border-left: 2px solid var(--text-color);
      transform: rotate(-45deg);
      right: 0;
      top: 6px;
    }
  }

  &__user {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    overflow: hidden;

    &-icon {
      width: 25px;
      height: 25px;
      min-width: 25px;
      border-radius: 50%;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &-name {
      overflow: hidden;
      text-overflow: ellipsis;
      color: var(--text-color);
      font-size: 14px;
      padding-left: 8px;
    }
  }
}

.project-rename input {
  text-align: right;
  padding: 0;
}