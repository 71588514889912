.error-boundary {
  &-overlay {
    position: absolute;
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: rgb(54, 54, 54);
  }

  &-error {
    color: red;
    border: 1px solid red;
    padding: 5px;
    border-radius: 4px;
  }
}
