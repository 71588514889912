.settings-input {
  $root: &;
  width: 100px;

  &__box {
    display: block;
    width: 100%;
    position: relative;
    cursor: text;

    input {
      text-align: center;
      border: none;
      outline: none;
      display: block;
      width: 100%;
      height: 33px;
      border-radius: 4px;
      background-color: var(--card-background);
      border: 1px solid var(--card-background);
      color: var(--text-color);
      padding: 0 5px 0 48px;
      font-size: 14px;
      line-height: 16px;

      &:hover,
      &:focus {
        border-color: var(--text-color);
      }
    }
    &--iconDisabled {
      input {
        padding-left: 5px;
      }
    }
  }

  &--secondary {
    #{$root}__box {
      input {
        background-color: transparent;
        border-color: #AFAFAF;
      }
    }
  }

  &__icon {
    width: 35px;
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 16px;
    position: absolute;
    top: 0;
    left: 0;
    color: var(--inactive-color);
    font-size: 12px;
  }

  &--disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}
