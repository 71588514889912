.info-view {
  height: 100%;
  padding: 20px 20px 24px;
  border-bottom: 1px solid var(--panel-color);
  color: var(--text-color);

  &__title {
    font-size: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}


