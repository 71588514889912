.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.title {
  display: flex;
  align-items: center;
  margin-bottom: 48px;
}

.stage {
  margin-top: 12px;
  margin-bottom: 12px;
}

.name {
  margin-right: 16px;
}

.size {
  color: var(--border-color-light);
}

.action {
  color: var(--activity-color);
  cursor: pointer;
}

.circular {
  position: relative;
  top: 0;
  left: 0;
  transform: none;
  background-color: #181818;
  box-shadow: none;
}

.svg {
  filter: drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.7))
}

.ready {
  box-shadow: 0px 0px 10px var(--activity-color);
  background-image: url('~assets/projects/import_ready.svg');
  background-position: center;
  background-repeat: no-repeat;
}

.error {
  box-shadow: 0px 0px 10px #EB5757;
  border-color: #EB5757;
  background-image: url('~assets/projects/import_error.svg');
  background-position: center;
  background-repeat: no-repeat;
}
