.raw-button {
  padding: 0;
  display: block;
  border: none;
  outline: none;
  line-height: 1;
  transition: all .3s;
  cursor: pointer;

  &[disabled] {
    pointer-events: none;
  }

  svg {
    transition: all .3s;
  }
}
