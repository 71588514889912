.input-rename {
  &--default input {
    font-size: 18px;
    line-height: 21px;
    color: var(--text-color);
    background: none;
    text-align: center;
    border: none;
    cursor: pointer;
  }

  &--ellipsis input {
    text-overflow: ellipsis;
  }
}