.projects {
  height: 100%;
  display: flex;
  flex-direction: column;

  &__caption {
    margin-right: auto;
    font-size: 24px;
    line-height: 28px;
    color: var(--text-color);
  }

  &__create-text {
    color: #9d9d9d;
    font-size: 24px;
    line-height: 28px;
    max-width: 234px;
    text-align: center;

    margin-top: 30px;

    position: relative;

    &__arrow {
      position: absolute;
      right: -40px;
      top: 20px;
    }
  }

  &__central-create-btn {
    max-width: 228px;
    min-height: 46px;

    margin-top: 24px !important;
  }

  &__central-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    flex-grow: 1;
  }

  &__top-bar {
    display: flex;

    padding: 24px;
    min-height: 32px;

    &__actions {
      display: flex;

      &__search {
        margin-right: 32px;
        min-width: 202px;
      }

      &__create-btn {
        max-width: 161px;
        width: auto;

        &:first-of-type {
          margin-right: 20px;
        }
      }

      .MuiInputBase-input {
        min-height: 32px;
      }
    }
  }

  &__nothing-found-text {
    font-size: 24px;
    line-height: 28px;
    color: #9d9d9d;
  }
}
