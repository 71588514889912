.volume {
  display: flex;
  align-items: center;
}

.checkbox {
  display: flex;
  align-items: center;

  & > span{
    padding: 0;
    margin-right: 12px;
  }
}

.label {
  composes: label from '../Options/options.module.scss';
}