@import 'styles/variables';

:global {
  .base-dialog {
    & :local {
      & .dialog {
        max-height: 440px;
        max-width: 656px;
        padding: 32px;
      }
    }
  }
}

.title {
  font-size: 24px;
}

.desc {
  max-width: none;
}

.inputContainer {
  width: 100%;
  height: 324px;
  margin-top: 24px;
  border-radius: 8px;
  border: 1px dashed $media-text-lightgray;
}

.error {
  height: auto;
  margin-bottom: 30px;
}

.checkboxContainer {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  font-size: 12px;
  color: var(--text-color);
}

.checkbox {
  margin-right: 10px;
  padding: 0;
}

.checkboxLabel {
  font-size: 12px;
}