.selectBox {
  position: relative;
  pointer-events: none;
  background-color: rgba(87, 111, 233, 0.2);
  border: 1px solid rgba(87, 111, 233, 1);

  // Fixed in react-selecable-fast-control source but still interesting approach

  // Disable border if width/height = 0

  // In case we set `border: 1px solid var(--absolute-color);` 
  // we have black 2*2 dot at the top-left corner 
  // despite height: 0, width: 0

  // Workaround:
  // we can't set border-width/box-shadow/outline based on width/height
  // but for bg we can

  // &:before {
  //   content: '';
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   height: 100%;
  //   width: 100%;
  //   background: linear-gradient(0deg, transparent calc(100% - 1px), rgba(87, 111, 233, 1) calc(100% - 1px)),
  //     linear-gradient(90deg, transparent calc(100% - 1px), rgba(87, 111, 233, 1) calc(100% - 1px)),
  //     linear-gradient(90deg, rgba(87, 111, 233, 1) 1px, transparent 1px),
  //     linear-gradient(0deg, rgba(87, 111, 233, 1) 1px, transparent 1px);
  // }
}