@import 'styles/variables';

.content {
  flex-grow: 1;
  margin-top: 20px;
}

.root {
  &:hover {
    background-color: var(--card-item-background);
  }
}