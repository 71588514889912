.x-icon {
  width: 24px;
  height: 24px;
  cursor: pointer;

  &:hover {
    svg {
      fill: var(--text-color);
    }
  }

  svg {
    fill: #9d9d9d;
    transition: all 0.3s;
  }
}
