.textarea-modal {
  &__header h2 {
    font-size: 24px;
    line-height: 28px;
  }

  &__field-name {
    font-size: 14px;
    color: var(--text-color-light);
    margin-bottom: 10px;
  }

  &__content {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
  }

  &__buttons {
    margin-top: 12px;
    display: flex;
    justify-content: flex-end;
  }

  &__button {
    width: 105px !important;

    &:last-child {
      margin-left: 12px;
    }
  }

  .MuiPaper-root.MuiDialog-paper  {
    padding: 32px;
  }

  textarea {
    border: none;
    outline: none;
    color: var(--text-color);
    border-radius: 8px 8px 0px 0px;
    background: var(--card-item-background-light);
  }
}