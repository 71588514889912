:root {
  // for a dark theme direction must be 1 (increase brightness)
  // for a light theme must be -1 (decrease brightness)
  --color-pallet-direction: 1;

  --act-h: 230;
  --act-s: 77%;
  --act-l: 63%;

  --activity-color: hsl(
    var(--act-h),
    var(--act-s),
    var(--act-l)
  );
  --activity-color-hovered: hsl(
    var(--act-h),
    var(--act-s),
    calc(var(--act-l) + 10%)
  );
  --activity-color-light: hsl(
    var(--act-h),
    var(--act-s),
    calc(var(--act-l) + 20%)
  );


  --slider-color: #da8338;
  --slider-color-sticky: #ff6347;

  --audio-wave-color: #83DC6D;
  --voice-wave-color: #94A7CD;

  --bg-h: 0;
  --bg-s: 0%;
  --bg-l: 19%;

  --background-color: hsl(var(--bg-h), var(--bg-s), var(--bg-l));
  --absolute-color: hsl(var(--bg-h), var(--bg-s), calc(var(--color-pallet-direction) * -100%));
  --absolute-color-reverse: hsl(var(--bg-h), var(--bg-s), calc(var(--color-pallet-direction) * 100%));
  --text-color: hsl(
    var(--bg-h),
    var(--bg-s),
    calc(var(--bg-l) + 90% * var(--color-pallet-direction))
  );

  --text-color-light: hsl(
    var(--bg-h),
    var(--bg-s),
    calc(var(--bg-l) + 50% * var(--color-pallet-direction))
  );

  --border-color: hsl(
    var(--bg-h),
    var(--bg-s),
    calc(var(--bg-l) - 19%)
  );

  --bg-25: hsl(
    var(--bg-h),
    var(--bg-s),
    25%
  );
  --bg-29: hsl(
    var(--bg-h),
    var(--bg-s),
    29%
  );
  --bg-50: hsl(
    var(--bg-h),
    var(--bg-s),
    50%
  );
  --bg-56: hsl(
    var(--bg-h),
    var(--bg-s),
    56%
  );
  --bg-62: hsl(
    var(--bg-h),
    var(--bg-s),
    62%
  );
  --bg-68: hsl(
    var(--bg-h),
    var(--bg-s),
    68%
  );
  --bg-76: hsl(
    var(--bg-h),
    var(--bg-s),
    76%
  );
  --bg-80: hsl(
    var(--bg-h),
    var(--bg-s),
    80%
  );
  --bg-89: hsl(
    var(--bg-h),
    var(--bg-s),
    89%
  );

  --divider-color: hsl(
    var(--bg-h),
    var(--bg-s),
    calc(var(--bg-l) - 8%)
  );
  --border-color-light: hsl(
    var(--bg-h),
    var(--bg-s),
    calc(var(--bg-l) + (21% * var(--color-pallet-direction)))
  );
  --control-background: hsl(
    var(--bg-h),
    var(--bg-s),
    calc(var(--bg-l) - 4%)
  );
  --scrollbar-background: hsl(
    var(--bg-h),
    var(--bg-s),
    calc(var(--bg-l) + 15% * var(--color-pallet-direction))
  );
  --scrollbar-thumb: hsl(
    var(--bg-h),
    var(--bg-s),
    calc(36% - 17% * var(--color-pallet-direction))
  );
  --panel-color: hsl(
    var(--bg-h),
    var(--bg-s),
    calc(var(--bg-l) - 6%)
  );
  --card-background: hsl(
    var(--bg-h),
    var(--bg-s),
    calc(var(--bg-l) - 9%)
  );
  --float-background: hsl(
    var(--bg-h),
    var(--bg-s),
    calc(19% - 19% * var(--color-pallet-direction))
  );
  --media-card-background: hsl(
    var(--bg-h),
    var(--bg-s),
    calc(var(--bg-l) - 9% * var(--color-pallet-direction))
  );
  --card-item-background-light: hsl(
    var(--bg-h),
    var(--bg-s),
    calc(var(--bg-l) + 6% * var(--color-pallet-direction))
  );
  --card-item-background: hsl(
    var(--bg-h),
    var(--bg-s),
    calc(var(--bg-l) + 12% * var(--color-pallet-direction))
  );
  --layer-item-background: hsl(
    var(--bg-h),
    var(--bg-s),
    calc(65% - 35% * var(--color-pallet-direction))
  );
  --line-color: hsl(
    var(--bg-h),
    var(--bg-s),
    calc(36% - 36% * var(--color-pallet-direction))
  );
  --radio-btn-color: hsl(
    var(--bg-h),
    var(--bg-s),
    calc(75% + 50% * var(--color-pallet-direction))
  );
  --radio-btn-hover: hsl(
    var(--bg-h),
    var(--bg-s),
    calc(44% - 24% * var(--color-pallet-direction))
  );
  --radio-btn-bg: hsl(
    var(--bg-h),
    var(--bg-s),
    calc(30% - 20% * var(--color-pallet-direction))
  );
  --inactive-color: hsl(
    var(--bg-h),
    var(--bg-s),
    calc(58% - 14% * var(--color-pallet-direction))
  );
  --text-color-secondary: hsl(
    var(--bg-h),
    var(--bg-s),
    calc(var(--bg-l) + 70% * var(--color-pallet-direction))
  );
  --font-btn: hsl(
    var(--bg-h),
    var(--bg-s),
    calc(25% + 5% * var(--color-pallet-direction))
  );
  --font-btn-active: hsl(
    var(--bg-h),
    var(--bg-s),
    calc(75% + 25% * var(--color-pallet-direction))
  );
  --icon-btn: hsl(
    var(--bg-h),
    var(--bg-s),
    calc(49% + 20% * var(--color-pallet-direction))
  );
  --icon-btn-disabled: hsl(
    var(--bg-h),
    var(--bg-s),
    calc(50% - 14% * var(--color-pallet-direction))
  );
  --popup-background: hsl(
    var(--bg-h),
    var(--bg-s),
    calc(51% - 38% * var(--color-pallet-direction))
  );
  --popup-border: hsl(
    var(--bg-h),
    var(--bg-s),
    calc(38% - 38% * var(--color-pallet-direction))
  );
  --player-slider-color: hsl(
    var(--bg-h),
    var(--bg-s),
    calc(77% + 23% * var(--color-pallet-direction))
  );
  --player-track-color: hsl(
    var(--bg-h),
    var(--bg-s),
    calc(57% - 18% * var(--color-pallet-direction))
  );
}

$background-color: var(--background-color);
$media-file-border-grey: var(--text-color-light);
$media-file-border-seleted: var(--activity-color);
$media-file-borderhover-darkgrey: #d3d3d3;
$text-color-lightgray: lightgray;
$gray: var(--control-background);
$blue: var(--activity-color);
$layer-item-border: var(--text-color);

$ruller-height:29px;
$slider-head-width:69px;
$slider-head-height:15px;

$timebar-text-color: #9e9e9e;
$timebar-color: #4F4F4F;
$timebar-text-font: 10px Arial;

$layer-height: 68px;
$timeruller-left-padding: 54px;

$media-text-lightgray: var(--text-color-light);
$media-svg-hover: #BDBDBD;
$media-black-dropdown: var(--panel-color);
$buttons-color: var(--text-color);
$light-blue: var(--activity-color-hovered);

$option-height: 25px;

$control-setting-width: 224px;

:export { timebarTextColor: $timebar-text-color;
          timebarColor: $timebar-color;
          timebarTextFont: $timebar-text-font;
          rullerHeight: $ruller-height;
          buttonsColor: $buttons-color;
          optionHeight: $option-height;
          backgroundColor: $background-color;
          layerHeight: $layer-height;
}
