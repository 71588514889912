.item {
  position: absolute;
  width: 100%;
  height: 4px;
  margin: 0 5px;
  background-color: var(--activity-color);

  &Btn {
    position: absolute;
    color: var(--text-color);
    font-size: 12px;
    background-color: var(--activity-color);
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 4px 8px;
    border: 2px solid var(--card-background);
    border-radius: 48px;
  }
}

