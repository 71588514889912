.save-settings {
  padding: 20px;
  height: 100%;
  overflow: hidden;
  text-align: left;

  &__head {
    border-bottom: 1px solid var(--panel-color);
    padding-bottom: 23px;
  }

  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 18px;
  }

  &__caption {
    font-size: 24px;
    color: var(--text-color);
  }

  &__close {
    width: 24px;
    height: 24px;
    cursor: pointer;

    &:hover {
      svg {
        fill: var(--text-color);
      }
    }

    svg {
      fill: #9d9d9d;
      transition: all .3s;
    }
  }

  &__control {
    display: flex;
  }

  &__name {
    padding-right: 20px;
    width: calc(100% - 242px);

    input {
      background-color: var(--card-background);
      border: 1px solid var(--card-background);
      border-radius: 4px;
      display: block;
      width: 100%;
      height: 33px;
      color: var(--text-color);
      padding: 0 8px;
      font-size: 13px;
      transition: all .3s;

      &:hover,
      &:focus {
        border-color: var(--text-color);
      }
    }
  }

  &__format {
    max-width: 140px;
    padding-right: 20px;
    color: var(--text-color);
  }

  &__btn-start {
    min-width: 105px;
    margin-left: auto;
  }

  &__body {
    padding: 24px 0;
  }

  &__info {
    font-size: 14px;
    margin-bottom: 24px;

    &-item {
      color: #8e8e8e;
      margin-bottom: 8px;

      span {
        color: var(--text-color);
      }
    }
  }

  &__descr {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__fullname {
    color: var(--text-color);
    font-size: 18px;
    padding-right: 20px;
    width: calc(100% - 105px);
  }

  &__title {
    color: var(--text-color);
    font-size: 18px;
    padding-right: 10px;
    display: flex;
    align-items: center;
  }

  &__btn-cancel {
    width: 105px;
  }

  &__bottom {
    display: flex;
    flex-wrap: wrap;
  }

  &__link {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: var(--activity-color);
    margin-bottom: 16px;

    a {
      font-size: 14px;
      color: var(--activity-color);
    }
    font-size: 14px;
  }

  &__btn-download {
    width: 129px;
  }

  &__text {
    margin-bottom: 16px;
    margin-top: 30px;
    width: 100%;

    p {
      font-size: 14px;
      color: var(--text-color);
    }
  }

  &__loader {
    width: calc(100% - 17px);
    height: 28px;
    border: 1px solid var(--text-color);
    background-color: var(--card-background);
    border-radius: 18px;
    padding: 3px;

    &-inner {
      background-color: var(--activity-color);
      border-radius: 18px;
      height: 100%;
    }
  }
}

  //TODO: remove after demo
  .MuiDialogTitle-root,
  .MuiDialogContent-root,
  .MuiDialogActions-root {
    background: var(--card-item-background-light);
    color: var(--text-color);
  }

  .MuiTypography-colorTextSecondary {
    color: var(--text-color) !important;
  }
