@import 'styles/variables';

.timeline-header {
  position: relative;
  z-index: 2;

  &__ruller {
    margin-left: $timeruller-left-padding;
    position: relative;
  }

  &--disabled {
    opacity: 0.5;
  }
}
