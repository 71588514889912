@mixin flex($direction: row, $j-content: center, $a-items: center) {
  display: flex;
  flex-direction: $direction;
  justify-content: $j-content;
  align-items: $a-items;
}

.clip-creator {
  position: absolute;
  width: 99.5%;
  top: -19.5px;
  left: 50%;
  transform: translateX(-50%);
  height: 16px;
  z-index: 2;
    &.split-preview {
      width: 99.1%;
    }
    &__container {
      position: absolute;
      top: 0;
      height: 16px;
    }
    &__bar, &__background {
      position: absolute;
      left: 0px;
      right: 0px;
      height: 16px;
      border-radius: 50%;
    }
    &__bar-resizer {
      position: absolute;
      top:0;
      width: 2px;
      height: 100%;
      line-height: 0.5;
      cursor: ew-resize;
      border-radius: 50%;
      &.draggable{
        cursor: grabbing;
      }

      &_l{
        left: -3px;
      }
      &_r{
        right: -1px;
        background-color: var(--background-color);
      }

      & .hidden-resizer {
        position: absolute;
        top: -4px;
        width: 4px;
        height: 20px;

        &_l {
          left: 0;
        }

        &_r{
          right: -2px;
        }
      }
    }
    &__transparent-remover {
      position: absolute;
      top: 4px;
      height: 8px;
      width: 3px;
      background-color: var(--background-color);
      z-index: -1;
      border-radius: 50%;

      &_l{
        left: 1.5px;
      }

      &_r{
        right: -1.5px;
      }
    }
    &__control{
      position: relative;
      height: 100%;
      width: 100%;
      background-color: transparent;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 3;
      background-color: var(--background-color);
      svg, path {
        fill: var(--activity-color);
      }
    }
    &__background {
      background-color: var(--background-color);
      z-index: 2;
    }
    &__drag-button {
      line-height: 0.5;
      width: 100%;
      height: 100%;
      margin: 0 1px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: grab;
      &.active {
        cursor: grabbing;
      }
      &.hidden {
        display: none;
      }
      &.expand {
        cursor: ew-resize;
      }
    }

    &__menu {
      margin-left: 4px;
      color: gray;
      @include flex(row, center, flex-end);
      font-size: 16px;
      cursor: pointer;
      transition: all 0.25s;
      display: none;
      &:hover {
          svg, &, div {
            fill: var(--activity-color);
            color: var(--activity-color);
          }
      }

      &.active {
          display: block;
          color: var(--activity-color);
          transition: all .3s;
      }
  }
  &__dropdown-menu {
    width: 233px;
    .dropdown-menu-item {
      &__hotkey {
        color: var(--card-item-background)
      }
    }
  }
}