.activation-component {
  z-index: 100; // material ui popovers z-index
  width: 100vw;
  height: 100vh;
  position: fixed;
  background: var(--card-item-background);
  top:0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  flex-direction: row;

  overflow: auto;

  .activation-divider {
    height: auto;
    margin: 32px 0;
    background: var(--scrollbar-background);
  }
}