@import 'styles/variables.scss';

.timeline-ruller {
    width: 100%;
    height: $ruller-height;
}

// making fixed position is a temporary solution to achieve
// scrolling timestamp. Ideally we should draw only
// visible part of timeline but scroll it together with
// layers and time slider. It also causes an issue of
// flickering time bar when scroll down and change scale
.timeline-canvas {
    // position: fixed;
    //position: absolute;
    //left: 0;
    //right: 0;
}
