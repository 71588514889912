.zoom-slider {
    height: 32px;
    display: flex;

    &__wrap {
        display: flex;
        border: 1px solid var(--border-color-light);
        background-color: var(--card-item-background);
    }

    &__btn {
        width: 32px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: all 0.3s;

        &:hover {
            background-color: var(--border-color-light);
        }

        svg {
            fill: var(--text-color);
            width: 20px;
            height: 20px;
        }
    }

    &__inner {
        width: 150px;
        border-left: 1px solid var(--border-color-light);
        border-right: 1px solid var(--border-color-light);
        display: flex;
        align-items: center;
        padding: 0 10px;

        .MuiSlider-rail {
            background-color: #212121;
        }

        .MuiSlider-track {
            background-color: #888;
        }

        .MuiSlider-thumb {
            background-color: var(--text-color);

            &.MuiSlider-active {
                box-shadow: none;
            }

            &:after {
                display: none;
            }
        }
    }

    &__control {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid var(--border-color-light);
        background-color: var(--card-item-background);
        width: 16px;
        height: 32px;
        margin-left: 2px;
        cursor: pointer;
        transition: all 0.3s;

        &:hover {
            background-color: var(--border-color-light);
        }
    }

    &__dots {
        width: 2px;
        height: 2px;
        background-color: var(--text-color);
        border-radius: 50%;
        display: block;
        position: relative;

        &:before,
        &:after {
            position: absolute;
            content: "";
            width: 2px;
            height: 2px;
            background-color: var(--text-color);
            border-radius: 50%;
            left: 0;
        }

        &:before {
            top: -5px;
        }

        &:after {
            bottom: -5px;
        }
    }
}
