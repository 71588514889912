@import "styles/variables.scss";

.menu-tabs {
  background-color: $media-black-dropdown;
  height: 100%;

  &__scrollbar {
    height: calc(100% - 52px) !important;

    &:hover {
      .scrollbar--vertical {
        opacity: 1;
      }
    }

    .scrollbar--vertical {
      background-color: transparent;
      opacity: 0;
      transition: all .3s;

      .scroll-thumb {
        margin-left: 2px;
        background-color: var(--scrollbar-background);
      }
    }
  }

  .MuiListItem-root {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    text-align: center;

    &:hover {
      .MuiTypography-displayBlock {
        color: var(--text-color);
      }

      svg {
        fill: var(--text-color);
        path {
          fill: var(--text-color);
        }
      }
    }

    &:before {
      position: absolute;
      content: "";
      width: 3px;
      height: 42px;
      top: 10px;
      right: 0;
      opacity: 0;
      border-radius: 2px;
      background-color: var(--activity-color);
      transition: all 0.3s;
      z-index: 3;
    }

    &.Mui-selected {
      background-color: transparent;
      // pointer-events: none;

      &:before {
        opacity: 1;
      }

      svg {
        fill: var(--text-color);
        path {
          fill: var(--text-color);
        }
      }

      .MuiTypography-displayBlock {
        color: var(--text-color);
      }
    }

    svg, path {
      fill: $media-file-border-grey;
      transition: all 0.3s;
    }
  }

  .MuiListItemIcon-root {
    min-width: auto;
  }

  .MuiTypography-displayBlock {
    font-size: 12px;
    color: $media-file-border-grey;
    line-height: 1.2;
    transition: all 0.3s;
  }

  .MuiTouchRipple-root {
    display: none;
  }
}
