.card {
  margin-bottom: 8px;
  font-size: 14px;
  padding: 16px;
  color: var(--text-color);
  border-radius: 4px;
  background: var(--panel-color);
  display: flex;
  justify-content: space-between;
  gap: 30px;
  > *:last-child {
    flex-shrink: 0;
  }
}

.colorSelection {
  margin-top: 12px;
  display: flex;
  align-items: center;
  &Description {
    font-size: 12px;
    margin-left: 10px;
  }
}

.example {
  padding: 12px;
  width: 204px;
  height: 80px;
  border-radius: 4px;
  border: 1px dashed var(--card-item-background);
}