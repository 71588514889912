.head-control {
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;

  &__caption {
    font-size: 24px;
    line-height: 1;
    color: var(--text-color);
    padding-right: 20px;
    text-align: left;
  }

  &__wrap {
    line-height: 1;
    margin-top: 8px;
  }

  &__import {
    min-width: 86px;
    width: 114px;
  }

  &__btn-menu {
    background-color: var(--activity-color);
    border-radius: 4px;
    font-size: 12px;
    color: #fff;
    padding: 6px 20px 6px 8px;
    cursor: pointer;
    position: relative;

    &--active {
      svg {
        transform: rotate(180deg);
      }
    }

    svg {
      position: absolute;
      right: 2px;
      top: 3px;
      fill: #fff;
      width: 20px;
      height: 20px;
      transition: all .3s;
    }
  }

  &__dropdown-menu {
    width: 187px;
    border: 1px solid var(--border-color);

    &.MuiPopover-paper {
      margin-top: 6px;

      .MuiList-padding {
        padding: 5px 0;
      }

      .MuiMenuItem-root {
        font-size: 12px;
        padding: 2px 16px;
      }
    }
  }

  &__btn {
    margin-left: 8px;

    .raw-button {
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        svg {
          fill: var(--bg-68);
        }
      }

      svg {
        fill: var(--text-color);
      }
    }
  }
}
