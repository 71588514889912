.select {
  transition: all 0.3s;
  height: 24px;
  width: 120px;
  background-color:  var(--card-item-background);
  color: var(--text-color);
  border: 1px solid  var(--card-item-background);
  border-radius: 0;
  &:hover {
    background-color: var(--border-color-light);
    border-color: var(--border-color-light);
  }
  &.active {
    background-color: var(--card-item-background);
    border: 1px solid var(--text-color);
  }
}

.only {
  width: 100px;
}

.selectLabel {
  display: flex;
  align-items: center;
  flex-grow: 1;
  & > *:first-child {
    margin-right: 6px;
  }
}

.menuSection {
  --active-color: var(--controls-secondary-color);
  display: flex;
  padding: 5px 6px;
  & > *:first-child {
    margin-right: 7px;
  }
  &.active {
    background-color: var(--active-color);
    color: var(--text-color);
    &:hover {
      color: var(--text-color);
      :local(.icon) {
        background-color: var(--text-color);
      }
    }
  }
  &:hover {
    color: var(--active-color);
    :local(.icon) {
      background-color: var(--active-color);
    }
  }
}

.menu {
  background-color: var(--card-item-background);
  border: 1px solid var(--card-item-background);
}

.list {
  padding-top: 4px;
  padding-bottom: 4px;
}

.icon {
  width: 14px;
  height: 14px;
  transition: all .3s;
  display: flex;
  mask-repeat: no-repeat;
  mask-position: center;
  background-size: contain;
  mask-size: contain;
  background-color: var(--text-color);
  &Voice {
    mask-image: var(--voice-asset-icon); }
  &Webcam {
    mask-image: url('~assets/timeline/webcam_icon.svg'); }
}