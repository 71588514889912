@import "styles/variables";

.layer {
  display: flex;
  height: $layer-height;
  // height: 64px;
  background-color: var(--card-background);
  border-top: 4px solid var(--background-color);

  &--hidden {
    visibility: hidden;
    .raw-button,
    .raw-button svg {
      transition: none;
    }
  }

  &__control {
    $margin: 2px;

    // Don't use real margin, so that *full* controls block width is measurable in js.
    // Very important for proper item positioning while dragging.
    width: $timeruller-left-padding;
    margin: $margin 0;

    display: flex;
    position: sticky;
    z-index: 2;
    background-color: var(--card-background);
    left: 0;

    &-col {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      padding: 2px 0;

      .layer__control-item {
        .raw-button {
          &:hover {
            svg, path {
              fill: var(--text-color);
            }
          }
        }
      }

      &:nth-child(1) {
        width: 20px;
        background-color: var(--control-background);
        margin-right: 2px;

        .layer__control-item {
          .raw-button {
            &:hover {
              svg, path {
                fill: var(--activity-color-hovered);
              }
            }

            svg, path {
              fill: var(--text-color-light);
            }
          }
          &--disabled {
            .raw-button{
              cursor: default;
            }
            svg, path {
              opacity: 0.5;
              fill: var(--text-color-light) !important;
            }
          }
        }
      }

      &:nth-child(2) {
        width: 30px;
        background-color: var(--control-background);

        .layer__control-item {
          .raw-button {
            &:hover {
              svg, path {
                fill: var(--text-color);
              }
            }

            svg, path {
              fill: var(--text-color-light);
            }
          }

          &--disabled {
            .raw-button{
              cursor: default;
            }
            svg, path {
              opacity: 0.5;
              fill: var(--text-color-light) !important;
            }
          }
        }
      }
    }

    &-item {
      width: 16px;
      height: 16px;
      display: flex;
      justify-content: center;
      align-items: center;

      .revert-icon {
        transform: scale(1, -1);
      }

      &--volume {
        margin-bottom: auto;
      }
    }

    &--disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }

  &__items {
    position: relative;
    flex-grow: 1;
    flex-shrink: 0;
    z-index: 0;
  }

  &__mask {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.6);
    z-index: 1;
    pointer-events: none;
  }

  &__import {
    width: 130px;
    height: 61px;
    border-radius: 4px;
    border: solid 1px var(--text-color-light);
    background-color: $background-color;
    margin-top: 2px;
    transition: all 0.3s;
    cursor: pointer;

    &:hover {
      border-color: var(--text-color);
    }

    .file-input__wrap {
      .input-container {
        height: 112px !important;
      }
      svg {
        width: 19px;
        height: 19px;
        margin-bottom: 3px;
      }
    }
  }
}
