.container {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ring {
  width: 128px;
  height: 128px;
  border-radius: 50%;
  border: 1px solid var(--activity-color);
  transform: rotate(0deg);
  animation: rotation 1s linear infinite;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background-color: var(--text-color);
    transform: translate(-50%, -50%);
  }
}

@keyframes rotation {
  to {
    transform: rotate(360deg) ;
  }
}

.circle {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90px;
  height: 90px;
  border-radius: 50%;
  background-color: var(--activity-color);
}

.content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 52px;
  color: var(--text-color);
}

.cancel {
  margin-top: 17px;
  font-size: 14px;
  color: var(--activity-color);
}