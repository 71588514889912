@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

.spin-animation {
  animation: spin linear infinite;
  animation-duration: 1.5s !important;
}
