.position-options-select {
  &.MuiFormGroup-root {
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: -8px;
  }

  .MuiIconButton-root {
    width: 35px;
    height: 25px;
    border: 1px solid var(--inactive-color);
    transition: all .3s;
    border-radius: 4px;
    margin-bottom: 8px;
    position: relative;

    &:hover {
      border-color: var(--absolute-color-reverse);

      .square {
        background-color: var(--absolute-color-reverse);
      }
    }

    &.Mui-checked {
      border-color: var(--absolute-color-reverse);

      .square {
        background-color: var(--absolute-color-reverse);
      }
    }

    .square {
      width: 7px;
      height: 7px;
      background-color: var(--inactive-color);
      position: absolute;
      transition: all .3s;

      &--top-left {
        left: 3px;
        top: 3px;
      }

      &--top-center {
        left: 50%;
        transform: translateX(-50%);
        top: 3px;
      }

      &--top-right {
        right: 3px;
        top: 3px;
      }

      &--middle-left {
        left: 3px;
        top: 50%;
        transform: translateY(-50%);
      }

      &--middle-center {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      &--middle-right {
        right: 3px;
        top: 50%;
        transform: translateY(-50%);
      }

      &--bottom-left {
        left: 3px;
        bottom: 3px;
      }

      &--bottom-center {
        left: 50%;
        transform: translateX(-50%);
        bottom: 3px;
      }

      &--bottom-right {
        right: 3px;
        bottom: 3px;
      }
    }
  }
}
