.position {
  display: flex;
  align-items: stretch;
  padding-bottom: 12px;

  &__control {
    width: 121px;
    margin-right: 17px;
  }

  &__box {
    width: 159px;
    height: 91px;
    border: 1px solid var(--card-item-background-light);
    position: relative;

    &:before {
      position: absolute;
      content: '';
      width: 73px;
      height: 29px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: var(--background-color);
    }
  }

  &__frame {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    margin-left: 33px;
    padding: 5px 0;
  }

  &__item {
    position: absolute;

    &--top,
    &--bottom {
      width: 73px;
      height: 31px;
      left: 50%;
      transform: translateX(-50%);
      background-color: var(--card-item-background);
    }

    &--top {
      top: 0;

      &:before,
      &:after {
        position: absolute;
        content: '';
        border: 18px solid transparent;
        border-top: 14px solid var(--card-item-background);
        top: 0;
      }

      &:before {
        border-right: 26px solid var(--card-item-background);
        left: -42px;
      }

      &:after {
        border-left: 26px solid var(--card-item-background);
        right: -42px;
      }
    }

    &--bottom {
      bottom: 0;

      &:before,
      &:after {
        position: absolute;
        content: '';
        border: 18px solid transparent;
        border-bottom: 14px solid var(--card-item-background);
        bottom: 0;
      }

      &:before {
        border-right: 26px solid var(--card-item-background);
        left: -42px;
      }

      &:after {
        border-left: 26px solid var(--card-item-background);
        right: -42px;
      }
    }

    &--left,
    &--right {
      width: 43px;
      height: 31px;
      top: 50%;
      transform: translateY(-50%);
      background-color: var(--card-item-background-light);
    }

    &--left {
      left: 0;

      &:before,
      &:after {
        position: absolute;
        content: '';
        border: 18px solid transparent;
        left: 0;
      }

      &:before {
        border-bottom: 13px solid var(--card-item-background-light);
        border-left: 27px solid var(--card-item-background-light);
        top: -29px;
      }

      &:after {
        border-top: 13px solid var(--card-item-background-light);
        border-left: 26px solid var(--card-item-background-light);
        bottom: -29px;
      }
    }

    &--right {
      right: 0;

      &:before,
      &:after {
        position: absolute;
        content: '';
        border: 18px solid transparent;
        right: 0;
      }

      &:before {
        border-bottom: 13px solid var(--card-item-background-light);
        border-right: 27px solid var(--card-item-background-light);
        top: -29px;
      }

      &:after {
        border-top: 13px solid var(--card-item-background-light);
        border-right: 26px solid var(--card-item-background-light);
        bottom: -29px;
      }
    }

    input {
      display: block;
      width: 100%;
      height: 100%;
      text-align: center;
      background-color: transparent;
      outline: none;
      border: none;
      font-size: 12px;
      color: var(--text-color);

      &::placeholder {
        opacity: 1;
      }
    }
  }
}
