.project-export-dialog {
  &__header h2 {
    font-size: 24px;
    line-height: 28px;
  }

  &__content {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__button {
    width: 105px !important;
  }

  .MuiFormGroup-root {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      gap: 16px;
  }

  .MuiRadio-root {
    padding: 12px;
  }

  .MuiDialog-paper {
    width: 400px;  // Custom width for the dialog
    max-width: 100%;  // Ensure it doesn't exceed the screen width
    padding: 32px 32px;
  }

  &__button-container {
    display: flex;
    justify-content: flex-end; // Align the button to the right
    margin-top: auto; // Ensure the button stays at the bottom
  }

  &__label {
    .MuiFormControlLabel-label {
      font-size: 14px !important;
      width: 100%;
      padding: 5px;
    }
  }

  &__switch {
    margin: 0 10px;
    padding: 1px;
  }

  &__link {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: var(--activity-color);
    margin-bottom: 16px;

    a {
      font-size: 14px;
      color: var(--activity-color);
    }
    font-size: 14px;
  }

  &__download-copy-container {
    display: flex;
    flex-direction: row;
  }

  &__btn-copy {
    min-width: 123px;
    margin-right: 16px;
  }
  &__btn-download {
    width: 129px;
  }

  &__loader {
    width: 100%;
    height: 28px;
    border: 1px solid var(--text-color);
    background-color: var(--card-background);
    border-radius: 18px;
    padding: 3px;

    &-inner {
      background-color: var(--activity-color);
      border-radius: 18px;
      height: 100%;
    }
  }
}
