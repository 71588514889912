.font-style-checkbox {
  width: 35px;
  height: 25px;

  &.MuiIconButton-root {
    border-radius: 4px;
    
    svg {
      fill: var(--font-btn);
      transition: all .3s;
    }

    &:hover {
      background-color: transparent;
    }

    &.Mui-checked {
      background-color: var(--font-btn-active);
  
      svg {
        fill: hsl(0, 0%, 20%);
      }
    }
  }
}

.MuiIconButton-root:hover {
  background: none;
  &:hover {
    &::after {
      content: "";
      width: 30px;
      height: 30px;
      position: absolute;
      background-color: rgba(0, 0, 0, 0.04);
      border-radius: 50%;
    } 
  }
}