.color-style {
  display: flex;

  &__picker {
    margin-right: 16px;
  }

  &__input {
    width: 100px;
  }

  &__box {
    display: block;
    width: 100%;
    position: relative;
    cursor: text;

    input {
      border: none;
      outline: none;
      display: block;
      width: 100%;
      height: 33px;
      border-radius: 4px;
      background-color: var(--card-background);
      border: 1px solid var(--card-background);
      color: var(--text-color);
      padding: 0 5px 0 48px;

      &:hover,
      &:focus {
        border-color: var(--text-color);
      }
    }
  }

  &__icon {
    width: 35px;
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 16px;
    position: absolute;
    top: 0;
    left: 0;
    color: var(--inactive-color);
    font-size: 12px;
  }

  &--disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}
