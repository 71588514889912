* {
  box-sizing: border-box;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

a#eXTReMe-Free-html5ed {
  display: none;
}
