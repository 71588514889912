.dialog {
  text-align: center;
  :global {
    .MuiDialog-paper {
    width: 496px;
    max-width: 496px;
    padding: 14px 24px;
    };
    .MuiDialogTitle-root {
      text-align: left;
      .MuiTypography-root {
        font-size: 20px;
      } 
    }
  }

}


.description {
    font-size: 18px;
    line-height: 21px;
}

.content {
  margin: 12px auto 17px;
  background-color: var(--background-color);
  border-radius: 4px;
}

.title {
  font-size: 24px;
  text-align: left;
  line-height: 28px;
  margin: auto;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  margin: auto;

  button {
    max-width: 112px;
  }
}

.buttonCancel {
  border: 1px solid var(--text-color);
  display: flex;
  margin-right: 16px;

  &:hover {
    background: var(--border-color-light);
    border: 1px solid var(--card-item-background);
  }
}
