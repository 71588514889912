.number-input {
  width: 84px;
  height: 33px;
  position: relative;

  input {
    display: block;
    width: 100%;
    height: 33px;
    background-color: var(--card-background);
    border-radius: 4px;
    padding: 0 20px 0 8px;
    color: var(--text-color);
    font-size: 14px;
  }

  &__buttons {
    position: absolute;
    right: 5px;
    top: 2px;
  }

  &__btn {
    color: var(--text-color);
    cursor: pointer;
    width: 15px;
    height: 15px;

    &:hover {
      svg {
        fill: var(--text-color);
      }
    }

    svg {
      width: 100%;
      height: 100%;
      fill: var(--text-color);
      transition: all .3s;
    }

    &--plus {
      svg {
        transform: rotate(180deg);
      }
    }

    &[disabled] {
      pointer-events: none;
      opacity: .4;
    }
  }
}
