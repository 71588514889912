.audio {
  &MainContainer {
    height: 100%;
    border-radius: 4px;
    border-left: 4px solid;
    overflow: hidden;
  }

  &Container {
    color: var(--text-color);
    height: 100%;
    overflow: hidden;
  }

  &TextContainer {
    position: absolute;
    left: 5px;
    top: 35%;
    display: flex;
    align-items: center;
    color: var(--text-color);
  }

  &Name {
    margin-left: 7px;
    font-size: 10px;
    white-space: nowrap;
  }

  &IconBox {
    width: 13px;
    height: 13px;
    margin-left: 6px;
    display: flex;
    flex-shrink: 0;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    mask-repeat: no-repeat;
  }
}

.audioIcon {
  composes: audioIcon from '~app.module.scss';
}

.audioItem {
  composes: audioBackground from '~app.module.scss';
}

.audioItemBorder {
  border-color: var(--audio-wave-color);
}

.voiceIcon {
  composes: voiceIcon from '~app.module.scss';
  mask-size: cover;
  width: 16px;
  height: 16px;
}

.voiceItem {
  composes: voiceBackground from '~app.module.scss';
}

.voiceItemBorder {
  border-color: var(--voice-wave-color);
}
