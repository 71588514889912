@import "styles/blockSize.scss";

.asset-settings .chroma-key-color {
  display: flex;

  &_label {
    .MuiFormControlLabel-label {
      font-size: 12px !important;
      width: 60px;
    }
  }

  &_switch {
    margin: 0 10px;
  }
  
}

.asset-settings {
  user-select: none;
  height: auto;
  min-height: $defaultMediaSettingsHeight;
  background: var(--background-color);
}