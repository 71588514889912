@import "styles/variables.scss";

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  font-size: 12px;
}

.controls {
  display: flex;
  align-items: center;
  min-height: 56px;
  width: 100%;
  background-color: $background-color;
  padding: 12px 20px;
  box-shadow: 0px -3px 8px rgba(0, 0, 0, 0.25);
}


.button {
  width: fit-content;
  font-size: 16px;
  padding: 10px 28px;
  transition: background-color 0s;
}

.start {
  margin-left: auto;
}

.icon {
  width: 14px;
  height: 14px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  svg, path {
    fill: white;
  }
}

.stopIcon {
  width: 12px;
  height: 12px;
  background-image: url('~assets/media/stop_record.svg');
  margin-right: 10px;
}

.deleteIcon {
  background-image: url('~assets/timeline/ic_delete.svg');
}

.delete {
  color: var(--text-color);
  margin-right: auto;
  background-color: transparent;
  border: 1px solid var(--text-color);
  padding: 10px 11px;

  > *:first-child {
    margin-right: 0;
  }

  & svg path {
    fill: var(--text-color);
  }
}

.timer {
  font-size: 14px;
  color:var(--text-color);
  padding: 8px 30px;
  background-color: var(--control-background);
  border-radius: 4px;
}

.stop {
  margin-left: 12px;
}