@import '~/styles/variables.scss';

.transitions-switch {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  &__text {
    width: calc(100% - #{$control-setting-width});
    max-width: 250px;
    font-size: 14px;
    color: var(--text-color);
  }

  &__control {
    width: $control-setting-width;
  }
}
