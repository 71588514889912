@import "variables.scss";
@import "animations.scss";
@import "fontSize.scss";
@import "blockSize.scss";

@import '~codemirror/lib/codemirror.css';
@import '~codemirror/theme/material.css';

* {
  box-sizing: border-box;
}

html,
body,
#root-app-solveig,
.wrapper {
  height: 100%;
  width: 100%;
}

body {
  overflow: hidden;
}

#root-app-solveig {
  overflow: hidden;
  background-color: var(--background-color);
  position: relative;
}

button {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

a {
  text-decoration: none;
}

p {
  margin: 0;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.hidden {
  visibility: hidden;
}

.display-none {
  display: none;
}

@font-face {
  font-family: 'Bradley Hand';
  src: local('Bradley Hand'), url(../assets/fonts/BradleyHand.woff) format('woff');
}

@font-face {
  font-family: 'Brush Script MT';
  src: local('Brush Script MT'), url(../assets/fonts/BrushScriptMT.woff) format('woff');
}

@font-face {
  font-family: 'DIN Condensed';
  src: local('DIN Condensed'), url(../assets/fonts/DINCondensed.woff) format('woff');
}

@font-face {
  font-family: 'Avenir';
  src: local('Avenir'), url(../assets/fonts/AvenirLight.woff) format('woff');
}

@font-face {
  font-family: 'Bodoni';
  src: local('Bodoni'), url(../assets/fonts/Bodoni.woff) format('woff');
}

.tooltip {
  .MuiTooltip-tooltip {
    padding: 0;
    font-size: 10px;
    margin: 0;
    font-family: 'Arial';
    font-weight: 400;
    max-width: 400px;
    padding: 8px;
    background-color: var(--border-color);
    border-radius: 4px;
    border: 1px solid var(--border-color-light);
  }
}

.menu {
  border: 1px solid var(--border-color);

  &.MuiPopover-paper {
    margin-top: 6px;

    .MuiList-padding {
      padding: 5px 0;
    }

    .MuiMenuItem-root {
      font-size: 12px;
      padding: 2px 16px;
    }
  }
}

.flex {
  display: flex;
}

.items-center {
  align-items: center;
}

.j-center {
  justify-content: center;
}

.j-space-between {
  justify-content: space-between;
}

.MuiPopover-root {
  z-index: 100 !important;
}

.svgInline {
  svg {
    width: 100%;
    height: 100%;
  }
}

.MuiTable-root {
  th, td {
    font-size: 12px;
    padding: 10px 12px;
    line-height: 20px;
  }
  tbody tr {
    &:hover {
      background-color: var(--card-item-background-light);
    }
  }
  th {
    color: var(--text-color-light);
  }
  td {
    color: var(--text-color);
    opacity: 0.7;
  }
}

.MuiTableCell-root {
  border-bottom: 1px solid var(--line-color);
}