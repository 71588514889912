.ui-chip {
  cursor: pointer;
  font-size: 12px;
  max-height: 24px;
  background-color: var(--panel-color);
  color: var(--text-color);
  &:hover {
    background-color: var(--card-item-background);
  }

  &.MuiChip-colorPrimary {
    background-color: var(--activity-color);
  }
}