.size-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 336px;

  .settings-input {
    width: 140px;

    input {
      padding-left: 72px;
    }
  }

  &__aspect-ratio-icon {
    cursor: pointer;
    path {
      fill: var(--text-color-light);;
    }
    &--active {
      path {
        fill: var(--text-color);
      }
    }
  }
}
