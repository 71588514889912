@import "styles/blockSize.scss";

.movable-modal {
  display: none;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  position: relative;

  &.background{
    background: rgba(0, 0, 0, 0.6 );
    z-index: 100;
  }

  &.active {
    display: block;
  }

  &__container {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: $defaultMediaSettingsWidth;
    height: $defaultMediaSettingsHeight;
    background-color: var(--scrollbar-thumb);
    border: 1px solid var(--border-color-light);
    z-index: 100;
    pointer-events: all;
    &.move{
      cursor: move;
    }

    &.active {
      display: block;
    }
  }
}