.Resizer {
  box-sizing: border-box;
  position: relative;
  z-index: 56;
  background-color: var(--divider-color);
  transition: all .3s;
  display: block;
}

.Resizer:hover {
  background-color: var(--activity-color);
}

.Resizer.horizontal {
  height: 4px;
  min-height: 4px;
  cursor: row-resize;
  width: 100%;
}

.Resizer.horizontal:before {
  position: absolute;
  content: '';
  width: 16px;
  height: 2px;
  background-size: 16px 2px;
  background-position: 50% 50%;
  mask-image: url("data:image/svg+xml,%3Csvg width='16' height='2' viewBox='0 0 16 2' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='15' cy='1' r='1' transform='rotate(90 15 1)' fill='%23C4C4C4'/%3E%3Ccircle cx='8' cy='1' r='1' transform='rotate(90 8 1)' fill='%23C4C4C4'/%3E%3Ccircle cx='1' cy='1' r='1' transform='rotate(90 1 1)' fill='%23C4C4C4'/%3E%3C/svg%3E%0A");
  background-color: var(--text-color-light);
  top: 1px;
  left: 50%;
  transform: translateX(-50%);
}

.Resizer.vertical {
  cursor: col-resize;
  width: 4px;
  min-width: 4px;
}

.Resizer.vertical:before {
  position: absolute;
  content: '';
  width: 16px;
  height: 2px;
  background-size: 16px 2px;
  background-position: 50% 50%;
  mask-image: url("data:image/svg+xml,%3Csvg width='16' height='2' viewBox='0 0 16 2' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='15' cy='1' r='1' transform='rotate(90 15 1)' fill='%23C4C4C4'/%3E%3Ccircle cx='8' cy='1' r='1' transform='rotate(90 8 1)' fill='%23C4C4C4'/%3E%3Ccircle cx='1' cy='1' r='1' transform='rotate(90 1 1)' fill='%23C4C4C4'/%3E%3C/svg%3E%0A");
  background-color: var(--text-color-light);
  left: -6px;
  top: 50%;
  transform: translateY(-50%) rotate(90deg);
}
