.disableFocusStyle {
  input:focus {
    background: transparent;
    border: none;
  }
}

.dropdown {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-top: 8px;
  background-color: var(--card-item-background);
  padding: 4px 7px;
  border-radius: 4px;
  width: 184px;
  color: var(--text-color);
  font-size: 12px;

  .controls {
    margin-left: auto;
    display: flex;
    align-items: center;
    cursor: pointer;

    div {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 16px;
      height: 16px;
      margin-left: 8px;
    }

    .reset {
      fill: var(--text-color-light);

      &:hover {
        fill: var(--text-color);
      }
    }

    .arrow {
      transition: all .3s;
      fill: var(--text-color);

      &.opened {
        transform: rotate(180deg);
        transform-origin: center;
      }
    }
  }
}

.menuContainer {
  height: 150px;
  width: 193px;

  :global {
    .Mui-selected {
      background-color: var(--activity-color);
      color: var(--text-color);

      &:hover {
        background-color: var(--activity-color);
      }

      &:before {
        opacity: 1;
      }
    }
  }

  .menuItem {
    padding: 0;
    &.noResults {
      color: var(--text-color-light);
    }
  }
}

.count {
  margin-left: auto;
}

.verticalTrack {
  background-color: transparent;
}

.search {
  composes: disableFocusStyle;
  margin-bottom: 8px;
  margin-right: 8px;

  input {
    border: none;
  }
}

.divider {
  background: var(--card-item-background);
}

.menuScrollbar {
  margin-bottom: 5px !important;
}