@import 'styles/variables.scss';

.slider-pointer {
  position: absolute;
  top: 5px;
  left: 0;

  width: 1px;
  height: 100%;
  overflow: visible; // to make header visible

  // pointer-events: none;

  display: flex;
  justify-content: center;

  &__head {
    min-width: $slider-head-width;
    height: $slider-head-height;

    background: var(--slider-color);
    border-radius: 20px;
    opacity: 0.8;
    cursor: ew-resize;
  }

  &__input {
    position: absolute;
    top: 4px;
    left: -28px;
    outline: none;
    border: none;

    width: calc(#{$slider-head-width} - 9px);
    height: calc(#{$slider-head-height} - 7px);

    background-color: transparent;
    color: var(--text-color);
    font: 7pt Arial;
    pointer-events: none;
  }

  &__body {
    position: absolute;
    left: 0;
    top: 15px;

    height: 100%;
    width: 100%;

    pointer-events: none;
    // TODO:
    //  color is color-picked from browser
    //  maybe it's not exactly the same as in design document
    background-color: var(--slider-color);
  }

  &__sticky {
    background:  var(--slider-color-sticky);
  }
}
