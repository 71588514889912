.section-placeholder {
  padding: 10px 20px;
  height: 100%;

  &__title {
    font-size: 24px;
    color: var(--text-color);
  }

  &__content {
    height: calc(100% - 30px);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 70px 0 20px;
  }

  &__text {
    color: #9d9d9d;
    font-size: 18px;
    margin-bottom: 28px;
  }
}
