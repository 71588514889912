.rotation-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 434px;

  &__item {
    display: flex;
    align-items: center;

    font-size: 12px;
    line-height: 14px;
    color: var(--text-color);

    &__label {
      user-select: none;
      margin-right: 16px;
    }

    &--clickable {
      cursor: pointer;
    }
  }

  .settings-input {
    width: 100px;

    input {
      padding-left: 64px;
    }
  }
}
