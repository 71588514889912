.source-preview {
  &-text {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100% - 26px);
    overflow: hidden;
    &__body {
      position: relative;
      height: 100%;
      margin-top: 10px;
      background: black;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}