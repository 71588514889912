.infoView {
  height: auto;
  border: none;
  padding: 10px 20px;
}

.options {
  padding-top: 21px;
  margin-bottom: 43px;
  &.disabled {
    opacity: 0.5;
  }
}

.label {
  width: 113px;
}

.selectContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: 36px;
  gap: 5px;

  .select {
    width: 100%;
    max-width: 100%;
  }
}

.checkbox {
  display: flex;
  align-items: center;
  margin-bottom: 2px;
}

.checkboxRoot {
  padding-left: 0;
}

.volume {
  margin-bottom: 36px;
}