$default-color: var(--inactive-color);
$hover-color: var(--text-color);

.settings-button {
  cursor: pointer;

  color: $hover-color;
  border: 1px solid $default-color;
  border-radius: 4px;
  padding: 3px 16px;

  transition: all .3s;

  &:hover {
    border: 1px solid $hover-color;
  }

  &:hover > &__icon {
    opacity: 1;
  }

  &__text {
    vertical-align: middle;
    user-select: none;

    font-size: 12px;
    line-height: 14px;
    margin-right: 10px;
  }

  &__icon {
    svg {
      vertical-align: middle;
    }
    opacity: 0.7;
    
    &__active {
      opacity: 1
    }
  }

  &__active {
    border-color: var(--text-color);
  }
}