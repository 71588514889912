.previewItem {
  background-color: rgba(87, 111, 233, 0.38);

  // https://kovart.github.io/dashed-border-generator/
  background-image: url("data:image/svg+xml;utf8,<svg width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'><rect  width='100%' height='100%' fill='none' rx='4' ry='4' stroke='rgba(176, 203, 255, 1)' stroke-width='4' stroke-dasharray='6 6' /></svg>");
  border-radius: 4px;
  position: relative;
}

.mediaItem {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 97.5px;
  opacity: 0.5;
  color: var(--text-color);
  background-color: var(--border-color);
}
