.expansion-panel {

    .MuiPaper-root {
        box-shadow: none;
        border-radius: 0;
        background-color: transparent;
    }

    .MuiButtonBase-root {
        padding: 0;
    }

    .MuiExpansionPanelSummary-content,
    .MuiExpansionPanelSummary-content.Mui-expanded {
        margin: 0;
        flex-grow: initial;
        background-color: var(--background-color);
        position: relative;
        z-index: 1;
        padding-right: 13px;
        padding-left: 28px;
        color: var(--text-color);
        font-size: 12px;
    }

    .MuiExpansionPanelSummary-root,
    .MuiExpansionPanelSummary-root.Mui-expanded {
        min-height: 25px;
        position: relative;
        display: flex;
        justify-content: flex-start;

        &:before {
            position: absolute;
            content: '';
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 100%;
            height: 1px;
            background-color: var(--bg-80);
        }
    }

    .MuiExpansionPanelSummary-expandIcon {
        position: absolute;
        left: 0;
        top: 4px;
        z-index: 2;
        transform: rotate(180deg);

        path {
            fill: var(--text-color);
        }
    }

    .MuiExpansionPanelSummary-expandIcon.Mui-expanded {
        transform: rotate(0);
    }

    .MuiExpansionPanelDetails-root {
        display: block;
        padding: 8px 0 8px 28px;
    }
}
