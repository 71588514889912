.container {
  display: flex;
  align-items: center;
  color: var(--text-color);
  font-size: 14px;
  background-color: #B83838;
  border-radius: 41px;
  padding: 8px 44px;
  white-space: pre-wrap;
}

.disabled {
  padding: 0;
}