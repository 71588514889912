.preview-player-cover {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: gray;

  svg {
    margin-bottom: 30px;
    min-height: 70px;
  }

  &__text {
    color: var(--text-color);
    font-size: 36px;

    span {
      font-weight: 700;
    }
  }
}

.gltransition {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 2;
}