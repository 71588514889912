.float-button-container {
  min-width: 100px;
  height: 32px;
  position: absolute;
  cursor: pointer;
  user-select: none;

  &--bottom-right {
    right: 0;
    bottom: 0;
  }

  &--bottom-left {
    left: 0;
    bottom: 0;
  }

  &--top-left {
    left: 0;
    top: 0;
  }

  &--top-right {
    right: 0;
    top: 0;
  }

  &:hover > .float-button {
    opacity: 1;
  }
}

.float-button {
  position: absolute;
  z-index: 10;
  opacity: 0.7;
  background-color: var(--float-background) !important;
}

.float-button-content {
  padding: 12px 8px;
  font-size: 12px;
  line-height: 14px;
  position: absolute;
  z-index: 11;
  color: white;
  top: 0;
  width: 100%;
  height: 100%;

  svg path {
    fill: white;
  }
}

.float-button-content,
.float-button-content > * {
  display: flex;
  align-items: center;
  justify-content: center;
}
