.base-form-field {
  color: var(--text-color);
  margin-bottom: 20px;

  &_caption {
    margin-bottom: 10px;
  }

  &_description {
    color: var(--text-color-light);
    font-size: 12px;
  }
}