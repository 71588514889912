.confirm-dialog {
  text-align: center;

  .MuiDialog-paper {
    max-width: 496px;
  }

  &__title {
    font-size: 24px;
    line-height: 28px;
    margin: auto;
  }

  &__description {
    font-size: 18px;
    line-height: 21px;
    margin: 16px auto 32px;
  }

  &__buttons {
    display: flex;
    justify-content: center;
    margin: auto;

    &__cancel {
      border: 1px solid var(--text-color) !important;
      display: flex !important;
      margin-right: 16px !important;

      &:hover {
        background: var(--border-color-light) !important;
        border: 1px solid var(--card-item-background) !important;
      }
    }

    button {
      max-width: 112px;
    }
  }
}
