.banner {
  .MuiDialog-paper {
    min-height: 500px;
    max-width: 500px;
    padding: 32px 0px 0px 32px;

    > *:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  &_title {
    color: var(--text-color);
    width: 350px;
    font-weight: 700;
    font-size: 18px;
    margin-top: 20px;
  }

  &_point {
    color: var(--text-color);
    font-weight: 700;
    max-width: 196px;

    &::before {
      display: inline-block;
      content: '';
      width: 8px;
      height: 8px;
      background-color: var(--text-color);
      border-radius: 50%;
      margin-right: 6px;
    }

    &_description {
      font-weight: 300;
      font-size: 12px;
      line-height: 14px;
      margin-left: 14px;
    }
  }

  &_button {
    border-radius: 50px;
    width: fit-content;
    height: 32px;

    span {
      font-size: 12px;
    }
  }

  &_link {
    color: #667EF3;
    font-size: 12px;
    cursor: pointer;
  }

  &_image {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}