.settings-head {
  position: relative;
  padding: 10px 20px;
  box-shadow: 0px 8px 10px -1px rgba(0, 0, 0, 0.13);
  z-index: 5;

  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 22px;
  }

  &__caption {
    font-size: 24px;
    color: var(--text-color);
    user-select: none;
  }

  &__close {
    cursor: pointer;

    &:hover {
      svg {
        fill: var(--text-color);
      }
    }

    svg {
      fill: #9d9d9d;
      transition: all 0.3s;
    }
  }

  &__descr {
    width: calc(100% - 250px);

    &-title {
      display: block;
      font-size: 14px;
      color: #9d9d9d;
      margin-bottom: 7px;
    }

    &-text {
      display: block;
      font-size: 18px;
      color: var(--text-color);

      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  &__btns {
    display: flex;
    justify-content: flex-end;
    width: 250px;

    .btn {
      width: 103px;
      margin-left: 18px;
    }
  }
}
