.timeline-toolbar {
  $root: #{&};
  $btn-size: 24px;

  display: flex;
  align-items: baseline;
  width: 100%;
  height: 42px;
  padding: 0 12px;
  user-select: none;

  &__history {
    &--disabled {
      fill: gray !important;
      cursor: default !important;
    }
  }

  &__left {
    display: inline-block;
    user-select: none;
    margin-right: 12px;
    align-self: center;

    $btn-spacing: 4px;
    $btn-count: 2;
    width: ($btn-size * $btn-count) + ($btn-spacing * ($btn-count - 1));

    #{$root}__history-item,
    #{$root}__item {
      .raw-button {
        &:hover {
          svg {
            fill: #99aafe;
          }
        }

        svg {
          fill: var(--activity-color);
        }
      }
    }
  }

  &__undo-redo {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  // apply to both, so empty item still takes it's place and nothing shifts in UI
  &__history-item,
  &__history-item .raw-button,
  &__item,
  &__item .raw-button {
    width: $btn-size;
    height: $btn-size;
  }

  &__history-item .raw-button,
  &__item .raw-button {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__duration {
    display: inline-block;
    font-size: 20px;
    color: var(--text-color);
    width: 165px;
    padding-top: 5px;
  }

  &__buttons {
    display: inline-flex;

    #{$root}__history-item,
    #{$root}__item {
      margin-right: 12px;
      
      .raw-button {
        color: var(--text-color);
        border: 1px solid var(--card-item-background);
        background-color: var(--card-item-background);

        & .icon {
          width: 16px;
          height: 16px;
          mask-repeat: no-repeat;
          mask-size: contain;
          mask-position: center;
          background-color: var(--text-color);
          &.deleteIcon {
            mask-image: url('~assets/timeline/ic_delete.svg')
          }
        }

        &:hover {
          background-color: var(--border-color-light);
          border-color: var(--border-color-light);
        }

        &[disabled] {
          & .icon {
            background-color: #8f8f8f;
          } 
          svg {
            fill: #8f8f8f;
          }
        }

        &--active {
          background-color: var(--card-item-background);
        }

        svg {
          fill: var(--text-color);
        }
      }

      &--mediarecording {
        width: min-content;
        margin-right: 12px;
      }

      &--cut {
        svg {
          margin-left: 1px;
        }
      }
    }
  }

  &--disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}
