.shadowContainer {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-left: -48px;
  margin-top: -12px;

  & > * {
    margin-left: 48px;
    margin-top: 12px;
  }

  .shadowOffset {
    display: flex;
    align-items: center;

    & > *:nth-child(1) {
      margin-right: 20px;
    }

    & > *:nth-child(2) {
      margin-right: 16px;
    }

    .shadowOffsetTitle {
      color: var(--text-color);
      font-size: 12px;
    }

    .shadowOffsetX {
      margin-right: 16px;
    }
  }

  .disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}