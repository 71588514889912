.container {
  display: flex;
  color: #9C9C9C;
  margin-bottom: 16px;

  .item {
    cursor: pointer;
    padding: 8px 0;
  }

  .placeholder {
    flex: 1 1 8px;
    max-width: 24px;
  }

  .selected {
    color: var(--text-color);
    border-bottom: 3px solid var(--activity-color);
  }
}