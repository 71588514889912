.import-url {
  &__btn-import {
      max-width: 105px;
  }

  &__line-form {
    margin-top: 24px;
    display: flex;
  }
}


