@import 'styles/variables.scss';

.folder-media-preview {
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--media-card-background);

  svg path {
    fill: var(--bg-68);
  }
}