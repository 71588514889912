.color-picker {
    width: 84px;
    position: relative;
    display: flex;

    &__btn {
        width: fit-content;
        height: 33px;
        background-color: var(--card-item-background);
        padding: 4px;
        border-radius: 4px;
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        transition: all .3s;

        &--enabled {
            cursor: pointer;
            &:hover {
                background-color: var(--text-color-light);
            }
        }

        &--active {
            background-color: var(--text-color);
        }
    }

    &__background {
        width: 35px;
        height: 25px;
        border-radius: 4px;
        background-color: var(--text-color);
        overflow: hidden;
        position: relative;

        div {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 2;
        }

        svg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
        }
    }

    &__icon {
        width: 25px;
        height: 25px;
    }

    &__widget {
        display: none;
        padding: 5px 0 20px;
        position: absolute;
        z-index: 6;

        &--active {
            display: block;
        }

        .sketch-picker  {
            width: 225px !important;
            background: var(--panel-color) !important;

            .flexbox-fix {
                border-color: var(--border-color) !important;
            }
        }
    }
}
