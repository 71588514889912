.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
}

.icon {
  width: 79px;
  height: 93px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.audioIcon {
  background-color: var(--absolute-color-reverse);
  mask-image: var(--audio-asset-icon);
}

.voiceIcon {
  background-color: var(--absolute-color-reverse);
  mask-image: var(--voice-asset-icon);
  mask-size: cover;
}

.name {
  font-size: 24px;
  color: var(--text-color);
  margin-top: 30px;
}