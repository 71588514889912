.btn {
  display: flex;
  font-family: 'Arial';
  font-size: 18px;
  color: #fff;
  line-height: 1.2;
  width: 100%;
  height: 32px;
  padding: 5px 15px 5px;
  box-shadow: none;
  text-transform: none;
  cursor: pointer;
  transition: all 0.3s;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;

  > *:first-child {
    margin-right: 4px;
  }

  &:hover {
    box-shadow: none;
  }

  &--primary {
    background-color: var(--activity-color);

    &:hover {
      background-color: var(--activity-color-hovered);
    }
  }

  &--text {
    color: var(--activity-color);
    width: fit-content;
    padding: 0;
  }

  &--secondary {
    background-color: #eb5757;

    &:hover {
      background-color: #cb1e1e;
    }
  }

  &--third {
    background-color: #038f33;

    &:hover {
      background-color: #085d25;
    }
  }

  &--border {
    border: 1px solid var(--text-color);
    color: var(--text-color);
    background-color: transparent;

    &:hover {
      background-color: var(--card-item-background);
      // color: var(--text-color-light);
    }
  }

  &--large-size {
    font-size: 14px;
    text-transform: uppercase;
    padding: 10px 15px;
    height: 40px;
  }

  // &.Mui-disabled {
  //   color: var(--text-color);
  //   opacity: .5;
  // }

  .MuiButton-label {
    text-align: center;
  }
 }

 .btn--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  pointer-events: none; // Prevents any events
}