.editor_menu {
  display: flex;
  justify-content: space-around;
  position: relative;
  padding: 10px 0;
  svg {
    cursor: pointer;
    rect {
      fill: var(--text-color-light);
    }
  }
  &:after {
    position: absolute;
    content: '';
    width: 70%;
    bottom: 0;
    border-bottom: 1px solid var(--line-color);
  }
}