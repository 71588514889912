.input {
  margin-bottom: 12px;

  input {
    padding: 9px;
  }

  input:focus {
    border-color: var(--text-color);
  }
}

.dropdown {
  width: 286px;
  margin: 0 auto;
  margin-bottom: 32px;
  
}

.menu {
  height: 237px;
}

.item {
  height: 32px;
  padding: 8px 12px;
}

.paper {
  margin-top: 0;
}