:root {
  --menu-item-hover: var(--activity-color);
}

.assets-panel {
  /* ... */
}

.vertical-scrollbar {
  .scroll-container__content {
    padding-bottom: 0 !important;
  }
}
