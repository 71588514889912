.search-input {
  position: relative;
  cursor: text;

  input {
    display: block;
    width: 100%;
    border: 1px solid var(--border-color-light);
    border-radius: 4px;
    height: 24px;
    background-color: transparent;
    outline: none;
    font-size: 12px;
    color: var(--text-color);
    padding: 0 20px 0 28px;
    transition: all 0.3s;

    &:focus {
      border-color: var(--text-color-light);
      background: var(--card-background);
    }
  }

  &__search-icon {
    g {
      stroke: var(--text-color);
    }
    position: absolute;
    // z-index: -1;
    top: 50%;
    left: 8px;
    transform: translateY(-50%);
  }

  &__search-clear {
    position: absolute;
    top: 50%;
    right: 6px;
    transform: translateY(-50%);
    cursor: pointer;
    fill: var(--text-color-light);
    transition: all 0.3s;

    &:hover {
      fill: var(--text-color);
    }
  }
}
