@import 'styles/variables';

.asset-settings {
  height: 100%;

  .scroll-container{
    flex-grow: 1;
  }

  &__content {
    padding: 20px 20px 0 20px;
  }

  .MuiFormControlLabel {
    &-label {
      color: var(--text-color);
      font-size: 14px;
      // width: $control-setting-width;
    }
  }
}
