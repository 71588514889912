.amazon-import {
  &__form {
    display: flex;
    flex-direction: column;

    .input-clearable__input {
      margin-bottom: 24px;
      &:first-child {
        margin-top: 24px;
      }
    }
  }

  &__actions {
    display: flex;
    justify-content: space-between;
    width: 100%;

    &__text {
      font-size: 16px;
      margin: 0;
      color: var(--text-color);
    }

    &__btn-import {
      max-width: 105px;
    }
  }
}
