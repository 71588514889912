.input {
  .MuiFormControl-root {
    display: block;
  }

  .MuiInputLabel-formControl {
    position: static;
    transform: none;
  }

  .MuiInput-root {
    width: 100%;
  }

  .MuiInputBase-input {
    box-sizing: border-box;
    transition: all .3s;
  }

  input {
    display: block;
    width: 100%;
    border: 1px solid var(--border-color-light);
    border-radius: 4px;
    height: 32px;
    background-color: transparent;
    outline: none;
    font-size: 12px;
    color: var(--text-color);
    padding: 10px;
    transition: all 0.3s;
  }
}