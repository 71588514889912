.progress {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--background-color);
  border-radius: 50%;
  z-index: 10;

  &:hover {
    .media-file__save-controls {
      opacity: 1;
    }
  }

  .MuiCircularProgress-colorPrimary {
    color: var(--activity-color);
    top: -2px;
    left: -2px;
    position: relative;
    animation-duration: 300s;
  }

  &--transparent {
    background-color: unset;
  }

  &--white {
    border: 2px solid var(--activity-color);
    box-shadow: 0 0 8px var(--activity-color);

    .MuiCircularProgress-colorPrimary {
      color: var(--text-color);
    }
  }

  &__text {
    font-size: 12px;
    color: var(--text-color);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
