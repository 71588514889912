.slider {
  height: 100%;
  &.preview-slider {
    position: absolute;
    width: 100%;
    height: 35px;
    top: -20px;

    .MuiSlider-thumb::after {
      top: -3px;
      left: -3px;
      right: -3px;
      bottom: -3px;
      content: "";
      position: absolute;
    }
  }
}
