$mainColor: #bdbdbd;

.io-points-slider {
  position: absolute;
  top: 28.8px;
  left: 0;
  height: 1px;
  width: 100vw;

  &__bar {
    background: $mainColor;
  }

  &__left-point, &__right-point {
    position: absolute;
    width: 9px;
    height: 24px;
    left: -4px;
    top: -24px;
    z-index: 3;
    cursor: ew-resize;

    div {
      position: absolute;
      bottom: -4px;
      width: 100%;
      height: 9px;
      border-radius: 50%;
      background: $mainColor;
    }
  }

  &__bar {
    height: 1px;
  }

  &__right-shadow, &__left-shadow {
    position: absolute;
    top: 5px;
    background: rgba(0, 0, 0, 0.5);
    z-index: -1;
    pointer-events: none;

    &.hidden {
      display: none;
    }
  }

  &__left-shadow {
    left: 0;
  }

  &__right-shadow {
    right: 0,
  }
}