@import 'styles/variables.scss';

.select {

  &.MuiInputBase-root {
    display: block;
    color: var(--text-color);
    font-size: 14px;
    text-align: left;
    height: 33px;
    background-color: var(--card-item-background);
    border-radius: 4px;
    position: relative;
    transition: all .3s;
    cursor: pointer;

    max-width: fit-content;

    &:hover {
      background-color: var(--text-color-light);
    }

    svg {
      position: absolute;
      right: 4px;
      top: 4px;
      z-index: 0;
      fill: var(--text-color);
    }
  }

  .MuiSelect-select.MuiSelect-select {
    height: 100%;
    box-sizing: border-box;
    padding: 8px 30px 8px 10px;
    position: relative;
    z-index: 2;
  }
}

.MuiPopover-paper {
  .scrollbar--horizontal {
    display: none;
  }

  .MuiListItem-root.MuiMenuItem-root {
    font-size: 14px;
    padding-left: 10px;
  }
}

.select-scrollbar .scroll-container__content {
  // position: unset !important;
}