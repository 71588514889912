.project-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  cursor: default;

  padding: 12px 24px;
  // excluded borders 2px
  margin: 14px 18px 0 22px;

  background: var(--card-background);
  border-radius: 4px;

  border: 2px solid transparent;

  &:hover {
    border: 2px solid var(--text-color);
  }

  &--active,
  &--active:hover {
    border: 2px solid var(--activity-color);
  }

  &--renaming,
  &--renaming:hover {
    border: 2px solid transparent;
  }

  &:first-child {
    margin-top: 8px;
  }

  &:last-child {
    margin-bottom: 32px;
  }

  &__name {
    &__text {
      color: var(--text-color);
      font-size: 21px;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      flex-grow: 1;
      margin-right: 18px;
    }

    &__form {
      display: flex;
      flex-grow: 1;
    }

    &__input {
      background: var(--absolute-color);
      border: 1px solid var(--border-color-light);
      border-radius: 4px;

      flex-grow: 1;
      margin-right: 18px;
      margin-left: -12px;

      .MuiInputBase-input {
        color: var(--text-color);
        font-size: 21px;
        line-height: 20px;
        caret-color: var(--border-color-light);

        padding: 6px 12px;
        height: 32px;
      }
    }

    &__save-btn.MuiButtonBase-root {
      display: flex;
      width: 112px;

      border: 1px solid var(--text-color);
      border-radius: 4px;
    }
  }

  &__date {
    min-width: 299px;
    margin-right: 56px;

    color: var(--text-color-light);
    font-size: 18px;
  }

  &__actions {
    display: flex;

    svg path {
      fill: var(--text-color);
    }

    * {
      cursor: pointer;

      &:hover {
        opacity: 1;
      }

      &:not(:last-child) {
        margin-right: 18px;
      }
    }
  }

  &__image {
    width: 54px;
    height: 37px;
    margin-right: 40px;

    &__empty {
      background: var(--card-background);
      border: 1px solid var(--text-color-light);

      width: inherit;
      height: inherit;
    }
  }

  &__context-menu {
      background: var(--card-background);
      border: 2px solid var(--text-color-light);
      padding: 5px 0;
      position: relative;
      z-index: 5;
      border-radius: 5px;
    .context-menu {
      &__item {
        background: var(--card-background);
        color: var(--text-color);
        font-size: 12px;
        padding: 2px 16px;
        height: 25px;
        font-size: 12px;
        transition: all .3s;
        line-height: 1.5;
        cursor: pointer;
        &:hover{
          color: var(--activity-color);
          background-color: transparent;
        }
      }
    }
  }
}
