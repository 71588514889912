@import './styles/variables.scss';

:root {
  --activity-color: #{$blue};

  --controls-primary-color: #{var(--text-color)};
  --controls-secondary-color: var(--activity-color, #{$blue});
  --audio-asset-icon: url('~assets/media/audio_preview.svg');

  --voice-asset-icon: url('~assets/media/voice_record.svg');
}

.audioIcon {
  mask-image: var(--audio-asset-icon, url('~assets/media/audio_preview.svg'));
  background-color: var(--text-color);
}

.baseAudioBackground {
  mask-image: url('~assets/timeline/wave_audio.svg');
  mask-size: auto 25px;
  mask-repeat: repeat no-repeat;
  mask-position: bottom;;
}

.audioBackground {
  composes: baseAudioBackground;
  background-color: var(--audio-wave-color) !important;
}

.voiceIcon {
  mask-image: var(--voice-asset-icon,  url('~assets/media/voice_record.svg'));
  background-color: var(--text-color);
}

.voiceBackground {
  composes: baseAudioBackground;
  background-color: var(--voice-wave-color) !important;
}
