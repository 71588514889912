.font-options-select {
  &.MuiFormGroup-root {
    height: 33px;
    background-color: var(--card-item-background);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
    padding: 0 4px;

    svg path {
      fill: var(--radio-btn-color);
    }
  }

  .MuiFormControlLabel-root {
    margin: 0;
  }

  .MuiIconButton-root {
    width: 35px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;

    svg {
      fill: var(--text-color);
      transition: all .3s;
    }

    &:hover {
      background-color: var(--radio-btn-hover);
    }

    &.Mui-checked {
      background-color: var(--radio-btn-bg);
    }
  }
}
