.input-clearable {
  &__x-icon {
    &--input {
      width: 16px;
      height: 16px;

      svg {
        width: 16px;
        height: 16px;
      }

      position: absolute;
      right: 8px;
      top: 8px;
    }
  }

  &__input {
    width: 100%;
    margin-right: 16px !important;
    position: relative;

    input {
      height: 32px;
      padding: 8px;

      background: var(--border-color);

      color: var(--text-color);
      border: 1px solid var(--border-color);

      border-radius: 4px !important;

      &:hover,
      &:focus {
        border-color: var(--text-color);
      }
    }
  }
}
