.confirmation-dialog {
  .MuiDialogTitle-root,
  .MuiDialogContent-root,
  .MuiDialogActions-root {
    background: var(--card-item-background-light);
    color: var(--text-color);
  }

  .MuiTypography-colorTextSecondary {
    color: var(--text-color) !important;
  }
}
