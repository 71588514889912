@import '~/components/AssetPanels/BaseAssetsPanel.scss';

.folderTreeContainer {
  position: relative;
  height: 183px;
  padding: 6px;
}

.tree {
  line-height: 1.5;
  cursor: pointer;
}

.file {
  padding-left: 20px;
  display: flex;
  align-items: center;

  span {
    padding-left: 5px;
  }
}

.folder {
  padding-left: 24px;
}

.treeIcons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.folderLabel {
  display: flex;
  align-items: center;
  margin-bottom: 4px;

  &:hover {
    color: var(--menu-item-hover);

    .folderIcon {
      color: var(--menu-item-hover);
    }
  }

  .expand {
    width: 16px;
    height: 16px;
  }

  .folderIcon {
    width: 16px;
    height: 16px;
    color: var(--bg-68);
  }

  span {
    padding-left: 6px;
  }
}

.folderSelectable {
  display: flex;
  align-items: center;
  padding: 0px 6px;

  &.folderSelected {
    color: var(--text-color);
    background: rgba(87, 111, 233, 0.49);
    border-radius: 2px;
  }
}

.folderTreeProgress {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}