.item {
  height: 62px;
}

@mixin thumb {
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 2px;
  height: 56px;
  background-color: var(--activity-color-light);
  border-radius: 1px;
}


.leftItem {
  background: linear-gradient(90deg, var(--activity-color) 0%, rgba(87, 111, 233, 0) 100%);
  position: relative;
  &:before {
    @include thumb;
    left: 0;
  }
}

.rightItem {
  background: linear-gradient(270deg, var(--activity-color) 0%, rgba(87, 111, 233, 0) 100%);
  position: relative;
  &:before {
    @include thumb;
    right: 0;
    
  }
}

.both {
  &:before {
    width: 1px;
  }
}
