.switch {
  &.MuiSwitch-root {
    padding: 0;
    width: 32px;
    height: 20px;
    border-radius: 50px;
    border: 1px solid var(--text-color);
    transition: all .3s;

    &:hover {
      border-color: var(--text-color);
    }
  }

  .MuiSwitch-switchBase {
    padding: 0;
    top: 1px;
    left: 1px;

    &.Mui-checked {
      transform: translateX(12px);

      .MuiSwitch-thumb {
        background-color: var(--activity-color);
      }
    }
  }

  .MuiSwitch-input {
    top: -3px;
    height: 20px;
  }

  .MuiSwitch-thumb {
    width: 16px;
    height: 16px;
    background-color: var(--text-color);
    box-shadow: none;
    transition: all .3s;
  }

  .MuiSwitch-track {
    background-color: transparent;
  }
}
