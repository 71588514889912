@import '../SaveSettings.scss';

.render-result-solveig {
  @extend .save-settings;

  &__btn-copy {
    min-width: 123px;
    margin-right: 16px;
  }
}
