.base-radio-icon {
  margin-right: 8px;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  box-shadow: inset 0 0 0 1px var(--text-color-light);

  &_checked {
    box-shadow: none;
    background-color: var(--activity-color);
    background-image: linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0));
    &:before {
      display: block;
      width: 16px;
      height: 16px;
      background-image: radial-gradient(#fff,#fff 28%,transparent 32%);
      content: "";
    }
  }
}