.dummy {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 184px;
  height: 112px;
  background: rgba(26, 26, 26, 0.64);
  border: 2px solid rgba(87, 111, 233, 0.5);
  border-radius: 4px;

  .item {
    color: var(--text-color);
    padding: 3px 4.5px;
    background: var(--activity-color);
    border-radius: 3px;
  }
}