@import "styles/variables.scss";

.layers {
  overflow: auto;
  flex-grow: 1;
  flex-shrink: 0;
  position: relative;
}

.timeline {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  height: 100% ;

  .wrapper {
    display: flex;
    flex-direction: column;
  }

  &:focus {
    outline: none;
  }

  &--disabled {
    pointer-events: none;
    opacity: 0.3;
  }

  &--hidden {
    visibility: hidden;

    .zoom-slider__btn,
    .zoom-slider__control,
    .timeline-footer__btn .MuiButton-root,
    .raw-button,
    .raw-button svg {
      transition: none !important;
    }
  }
}

.timeline-error-modal{
  .MuiPaper-root{
    max-width: 400px;
  }
}

#allocation_area {
  position: relative;
}


#allocation_area-container {
  border: 1px #000 solid;
  background: rgba(153, 204, 255, 0.5);
  position: absolute;
  display: block;
  z-index: 10;
}

#layers_aria{
  overflow: hidden;
}