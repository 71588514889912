.custom_css {
  &_radio {
    margin: 5px 5px 0 0;
  }
}

.customization_audio-example {
  g {
    &:nth-last-child(5) {
      rect {
        fill: var(--card-item-background);
      }
    }

    &:nth-last-child(3) {
      path {
        &:nth-last-child(2),
        &:nth-last-child(3) {
          fill: var(--text-color);
        }
      }
    }
  }
}