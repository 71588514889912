$root: #{&};

.preview-player-toolbar {
  &__right {
    display: flex;
    align-items: center;

    svg path {
      fill: var(--text-color);
    }

    #{$root}__item {
      &:last-child {
        margin-right: 0;
        }
    }
  }
}