@import 'styles/variables';

.asset-transition-field {
  .MuiFormControlLabel-labelPlacementStart {
    margin-left: 0;
  }

  & + & {
    margin-top: 20px;
  }
}
